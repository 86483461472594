<script>
import { defineComponent } from 'vue'
import ExceptionDisplay from '@/components/ExceptionDisplay.vue'
import LoadingDisplay from '@/components/LoadingDisplay.vue'

const filterAllStudents = 'all-students'
const filterPlansActive = 'plans-active'
const filterPlansEnded = 'plans-ended'
const filterTrialsActive = 'trials-active'
const filterTrialsEnded = 'trials-ended'
const filterIssues = 'issues'
const filterDisabled = 'disabled'
const filterFreePlans = 'free-plans'

export default defineComponent({
  components: { ExceptionDisplay, LoadingDisplay },
  name: 'StudentsPage',
  data () {
    return {
      isLoading: false,
      searchTerm: '',
      selectedFilter: filterAllStudents,
      allFilters: {},
      allStudents: [],
      error: '',
      apiClient: {}
    }
  },

  async mounted () {
    const filterParam = this.$route.params.filter
    // reset
    this.isLoading = false
    this.searchTerm = ''
    this.selectedFilter = filterParam || filterAllStudents
    this.apiClient = await this.$api.createApiClient()
    this.runSearch()
  },

  computed: {
    filteredStudents: function () {
      if (this.selectedFilter !== filterAllStudents) {
        return this.allStudents.filter((s) => this.shouldDisplayStudent(s, this.selectedFilter))
      } else {
        return this.allStudents
      }
    },
    countAll: function () {
      return this.allStudents.length
    },
    countFiltered: function () {
      return this.filteredStudents.length
    },
    countFreePlans: function () {
      return this.allStudents.filter((s) => this.shouldDisplayStudent(s, filterFreePlans)).length
    },
    countDisabled: function () {
      return this.allStudents.filter((s) => this.shouldDisplayStudent(s, filterDisabled)).length
    },
    countTrialActive: function () {
      return this.allStudents.filter((s) => this.shouldDisplayStudent(s, filterTrialsActive)).length
    },
    countTrialEnded: function () {
      return this.allStudents.filter((s) => this.shouldDisplayStudent(s, filterTrialsEnded)).length
    },
    countPlanActive: function () {
      return this.allStudents.filter((s) => this.shouldDisplayStudent(s, filterPlansActive)).length
    },
    countPlanEnded: function () {
      return this.allStudents.filter((s) => this.shouldDisplayStudent(s, filterPlansEnded)).length
    },
    countIssues: function () {
      return this.allStudents.filter((s) => this.shouldDisplayStudent(s, filterIssues)).length
    }
  },

  methods: {
    runSearch: function () {
      this.isLoading = true
      this.apiClient
        .get('students?search=' + this.searchTerm)
        .then((r) => {
          this.allStudents = r.data
          this.generateFilterList()
        })
        .catch((e) => {
          const status = e.status || (e.response ? e.response.status : 0)
          if (status === 401) {
            this.$api.handleLoginExpired()
          } else if (status === 400) {
            this.handleBadRequest(e.response)
          } else {
            this.handleError(e)
          }
        })
        .then(() => {
          this.isLoading = false
        })
    },
    generateFilterList: function () {
      this.allFilters = {}
      this.allFilters[filterAllStudents] = 'All (' + this.countAll + ')'
      this.allFilters[filterPlansActive] = 'Active Plans (' + this.countPlanActive + ')'
      this.allFilters[filterPlansEnded] = 'Ended Plans (' + this.countPlanEnded + ')'
      this.allFilters[filterTrialsActive] = 'Active Trials (' + this.countTrialActive + ')'
      this.allFilters[filterTrialsEnded] = 'Ended Trials (' + this.countTrialEnded + ')'
      this.allFilters[filterIssues] = 'Issues (' + this.countIssues + ')'
      this.allFilters[filterDisabled] = 'Disabled (' + this.countDisabled + ')'
      this.allFilters[filterFreePlans] = 'Free Plans (' + this.countFreePlans + ')'
    },
    handleError: function (e) {
      const errString = JSON.stringify(e)
      console.error('api error', errString)
      this.error = e.message
    },
    shouldDisplayStudent: function (s, filter) {
      switch (filter) {
        case filterPlansActive:
          return s.status === 3
        case filterPlansEnded:
          return s.status === 4
        case filterTrialsActive:
          return s.status === 1
        case filterTrialsEnded:
          return s.status === 2
        case filterIssues:
          return s.status === 5
        case filterDisabled:
          return s.isDisabled
        case filterFreePlans:
          return s.isOnFreePlan
        default:
          return true
      }
    }
  }
})
</script>

<template>

  <div class="row heading" data-test-id="page-student-search">
    <h4>Student Search ({{ countAll }})</h4>
    <p class="grey-text">Search by name or status</p>
  </div>

  <div class="row">
    <!-- search box -->
    <div class="col s12 l6">
      <div class="input-field">
        <strong>Search</strong>
        <input class="right" id="search-box" v-model="searchTerm" type="search" placeholder="Search for Students" @keyup.enter="runSearch()">
      </div>
    </div>

    <div class="col s12 l6">
      <strong>Filter</strong>
      <select v-model="selectedFilter" class="browser-default">
        <option v-for="(value, name) in allFilters" :key="name" :value="name">{{ value }}</option>
      </select>
    </div>
  </div>

  <div class="row">
    <exception-display :error="error"></exception-display>
    <loading-display :isLoading="isLoading"></loading-display>
  </div>

  <div class="row">
    <table v-if="!isLoading" class="highlight">
      <tbody>
        <tr v-for="s in filteredStudents" v-bind:key="s.id" data-test-id="student-search-result-item">
          <td>
            <i class="material-icons grey-text">person</i>
          </td>
          <td>
            <router-link :to="{ name: 'Student', params: { id: s.id }}" data-test-id="student-search-result-item-nav" :data-test-value="s.fullName">
              {{ s.firstName }}
            </router-link>
          </td>
          <td>
            <router-link :to="{ name: 'Student', params: { id: s.id }}">
              {{ s.lastName }}
            </router-link>
          </td>
          <td>
            <div>{{ s.statusFormatted }}</div>
            <div><span v-if="s.isDisabled" class="grey-text">Disabled</span></div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

</template>
