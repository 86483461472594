<template>
    <app-header />
    <div class="container center" v-if="!isBrowserValid">
      <h4>Oss Gym: Portal</h4>
      <loading-display :isLoading="isLoading" :is-white="false" />
      <exception-display :error="error" :hide-hints="true"></exception-display>
      <div v-if="isLoading">Initializing...</div>
    </div>
    <main>
      <div class="container" v-if="isBrowserValid">
        <router-view />
      </div>
    </main>
    <app-footer />
</template>

<script>
import AppHeader from '@/components/AppHeader.vue'
import AppFooter from '@/components/AppFooter.vue'
import LoadingDisplay from '@/components/LoadingDisplay.vue'
import ExceptionDisplay from '@/components/ExceptionDisplay.vue'

export default {
  components: { AppHeader, AppFooter, LoadingDisplay, ExceptionDisplay },
  data () {
    return {
      isBrowserValid: false,
      isLoading: true,
      error: ''
    }
  },
  async created () {
    await this.runChecks()
  },
  async mounted () {
    document.runMaterializeInit()
    this.$api.handleLoginStateChange()
  },
  errorCaptured (errMsg, vm, info) {
    document.popToast('Sorry! There has been an unhandled error. Please refresh the page and try again')
  },
  methods: {
    runChecks: async function () {
      this.isBrowserValid = false
      this.error = ''
      this.isLoading = true

      var isLocalStorageEnabled = await this.doLocalStorageCheck()
      if (!isLocalStorageEnabled) {
        this.isLoading = false
        this.error = 'No local storage is available on this web browser, please try another one'
        return
      }

      this.isBrowserValid = true
    },
    doLocalStorageCheck: function () {
      try {
        var mod = 'haslocalstoragecheck'
        localStorage.setItem(mod, mod)
        localStorage.removeItem(mod)
        return true
      } catch (e) {
        return false
      }
    }
  }
}
</script>

<style>
body, .app-container {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}

main {
    flex: 1 0 auto;
}

td {
    vertical-align: top;
}

.oss-blue-background {
    background-color: #0D47A1;
}

.oss-blue-text {
    color: #0D47A1;
}

.cta-blue-text {
    color: #039BE5;
}

.cta-blue-background {
    color: #039BE5;
}

.inline {
    display: inline-block;
}

.margin-small {
    margin: 2px;
}

.margin-small-bottom {
    margin-bottom: 2px;
}

.margin-medium {
    margin: 5px;
}

.margin-large {
    margin: 10px;
}

.margin-large-top {
    margin-top: 10px;
}

.margin-large-left {
    margin-left: 10px;
}

.margin-large-right {
    margin-right: 10px;
}

.margin-large-bottom {
    margin-bottom: 10px;
}
.margin-large-top-bottom {
    margin-top: 10px;
    margin-bottom: 10px;
}

.margin-medium-top-bottom {
    margin-top: 5px;
    margin-bottom: 5px;
}

.margin-medium-left {
    margin-left: 5px;
}

.margin-medium-right {
    margin-right: 5px;
}

.margin-medium-bottom {
    margin-bottom: 5px;
}

.padding-small {
    padding: 2px;
}

.padding-medium {
    padding: 5px;
}

.padding-medium-top-bottom {
    padding-top: 5px;
    padding-bottom: 5px;
}

.padding-medium-left {
    padding-left: 5px;
}

.padding-medium-right {
    padding-right: 5px;
}

.padding-medium-bottom {
    padding-bottom: 5px;
}

h4.dashboard-school-name {
    margin-top: 2px;
}

@media screen and (min-width: 993px) {
    .logged-in main {
        padding-left: 200px;
    }

    .logged-in #header-nav {
        padding-left: 200px;
    }

    .logged-in footer {
        padding-left: 200px;
    }
}

@media screen and (min-width: 601px) {
    .school-display {
        height: 64px;
    }
}

@media screen and (max-width: 600px) {
    .school-display {
        height: 56px;
    }
}

fieldset {
    border: 1px solid #ccc;
}

footer {
    padding: 10px;
}

video {
    width: 100%;
    height: auto;
}

#slide-out {
    max-width: 200px;
}

.nowrap {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* ======= ERRORS ======= */

.error-message {
    padding: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.error-message i {
    margin: 0 5px;
}

/* ======= ERRORS ======= */

.clickable {
    cursor: pointer;
}

.icon-and-text i,
.icon-and-text span,
.icon-and-text img {
    vertical-align: middle;
}

img.oss-logo {
    max-height: 35px;
    vertical-align: middle;
    margin-bottom: 8px;
}

/* ============ MATERIALIZE FIXES ============ */

.btn {
    margin-bottom: 5px;
}

/* --- menu --- */

header li a i {
    margin-right: 10px !important;
}

header li a {
    padding: 0 10px !important;
}

/* --- check in page --- */

.search-result-line {
    display: inline-block;
}

    .search-result-line i,
    .search-result-line span {
        vertical-align: middle;
    }

    .search-result-line i {
        margin-right: 10px;
    }

.checkin-search-result-name {
    font-size: 1.2em;
    font-weight: 500;
}

/* === student photo */
img.photo {
    height: 150px;
    min-height: 150px;
    max-height: 150px
}

img.photo-medium {
    height: 100px;
    min-height: 100px;
    max-height: 100px
}

img.photo-small {
    height: 50px;
    min-height: 50px;
    max-height: 50px
}

/* === check input page === */

.scanner-button {
    text-align: center;
}

.scanner-heading {
    text-align: center;
}

.check-in-title h5 {
    padding-left: 20px;
}

@media screen and (min-width: 601px) {

    .top-gap {
        min-height: 40px;
        height: 40px;
    }

    img.school-logo {
        max-height: 150px;
        min-height: 150px;
        height: 150px;
    }
}

@media screen and (max-width: 600px) {

    .top-gap {
    }

    img.school-logo {
        max-height: 80px;
        min-height: 80px;
        height: 80px;
    }
}

.class-selection-item label {
    display: block;
}

@media screen and (min-width: 601px) {
    .class-selection-item label {
        padding: 15px 5px;
    }
}

@media screen and (max-width: 600px) {
    .class-selection-item label {
        padding: 10px 2px;
    }
}

.class-selection-item-container {
    display: inline-block;
}

/* < SPINNER from LOADING.IO > */
.lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
}

.lds-dual-ring:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 4px solid #0D47A1;
    border-color: #0D47A1 transparent #0D47A1 transparent;
    animation: lds-dual-ring 0.7s linear infinite;
}

.lds-dual-ring-white {
    border: 4px solid #FFF;
    border-color: #FFF transparent #FFF transparent;
}

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
/* < SPINNER from LOADING.IO > */

/* < SPINNER from LOADING.IO > */
.lds-dual-ring-white {
    display: inline-block;
    width: 80px;
    height: 80px;
    border: 0;
}

.lds-dual-ring-white:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 4px solid transparent;
    border-color: #FFF transparent #FFF transparent;
    animation: lds-dual-ring 0.7s linear infinite;
}

@keyframes lds-dual-ring-white {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
/* < SPINNER from LOADING.IO > */

.filter-option {
    display: inline-block;
}

.filter-option span {
    margin: 10px;
}

.button-strip a {
    margin-right: 4px !important;
}

</style>
