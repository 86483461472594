import axios from 'axios'
import { useGtag } from 'vue-gtag-next'
import emitter from 'tiny-emitter/instance'

const eventKeyLoginExpired = 'login-expired'
const eventKeyLoginStatusChanged = 'login-status-changed'
const apiBaseUrl = process.env.VUE_APP_APIBASEURL

const api = {
  isLoginRedirectCallback: false,
  setAnalyticsUserId: function () {
    const schoolId = localStorage.getItem('schoolId') || ''
    if (schoolId) {
      const { set } = useGtag()
      set({ user_id: schoolId })
    }
  },
  createApiClient: async function () {
    const accessToken = localStorage.getItem('accessToken')
    if (accessToken) {
      return axios.create({
        baseURL: apiBaseUrl,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'x-auth-mode': '1',
          'x-site-key': 'portal'
        }
      })
    } else {
      return axios.create({ baseURL: apiBaseUrl })
    }
  },
  saveNewLogin: function (data: any) {
    localStorage.setItem('accessToken', data.accessToken)
    localStorage.setItem('refreshToken', data.refreshToken)
    localStorage.setItem('schoolId', data.schoolId)
    localStorage.setItem('schoolName', data.schoolName)
    localStorage.setItem('schoolPhotoUrl', data.schoolPhotoUrl)
    this.setAnalyticsUserId()
    emitter.emit(eventKeyLoginStatusChanged)
  },
  logout: async function () {
    this.handleLoginExpired()
  },
  getIsLoggedIn: async function () {
    const accessToken = localStorage.getItem('accessToken')
    return !!accessToken
  },
  handleLoginExpired: function () {
    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')
    localStorage.removeItem('schoolId')
    localStorage.removeItem('schoolName')
    localStorage.removeItem('schoolPhotoUrl')
    emitter.emit(eventKeyLoginExpired)
  },
  handleLoginStateChange: function () {
    emitter.emit(eventKeyLoginStatusChanged)
  },
  handleFailedRequest: function (ex: any, redirOnAuthFailed: boolean) {
    // console.debug('e', JSON.stringify(ex))
    const status = ex.status || (ex.response ? ex.response.status : 0)
    const data = {
      validationErrors: [] as any[],
      error: ''
    }
    if (status === 401) {
      if (redirOnAuthFailed) {
        this.handleLoginExpired()
      } else {
        data.validationErrors = ['Login failed']
      }
    } else if (status === 400) {
      const errArr = ex.response?.data?.errors || []
      if (errArr.length > 0) {
        data.validationErrors = ex.response.data?.errors || ['Invalid request']
      } else {
        data.validationErrors = [ex.message || 'Unexpected error (code 400)']
      }
    } else if (status === 404) {
      data.validationErrors = ['Item cant be found']
    } else {
      // const errString = JSON.stringify(ex)
      // console.error('api error', errString)
      data.error = 'Oops, unexpected error! (error code ' + status + ')'
    }
    return data
  }

}

export default api
