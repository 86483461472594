<script>
import { defineComponent } from 'vue'
import Chart from 'chart.js'
import chartTrendline from 'chartjs-plugin-trendline'
import ExceptionDisplay from '@/components/ExceptionDisplay.vue'
import LoadingDisplay from '@/components/LoadingDisplay.vue'
import ValidationErrorDisplay from '@/components/ValidationErrorDisplay.vue'

const sleepForMs = m => new Promise(resolve => setTimeout(resolve, m))

export default defineComponent({
  components: { ExceptionDisplay, LoadingDisplay, ValidationErrorDisplay },
  name: 'DashboardPage',
  data () {
    return {
      isLoading: false,
      isLoaded: false,
      validationErrors: [],
      error: '',
      schoolName: '',
      schoolPhotoUrl: ''
    }
  },

  async mounted () {
    this.schoolName = localStorage.getItem('schoolName')
    this.schoolPhotoUrl = localStorage.getItem('schoolPhotoUrl')
    await this.loadData()
  },

  computed: {
    hasRecentActivityChartData: function () {
      var hasData = false

      if (this.data.recentActivity && this.data.recentActivity.chartData && this.data.recentActivity.chartData.datasets) {
        hasData = (this.data.recentActivity.chartData.datasets.length > 0)
      }

      return hasData
    },
    hasSchoolPrgressChartData: function () {
      var hasData = false

      if (this.data.schoolProgress && this.data.schoolProgress.chartData && this.data.schoolProgress.chartData.datasets) {
        hasData = (this.data.schoolProgress.chartData.datasets.length > 0)
      }

      return hasData
    }
  },

  methods: {
    loadData: async function () {
      this.isLoading = true
      this.error = ''
      const apiClient = await this.$api.createApiClient()
      apiClient
        .get('dashboards')
        .then((r) => {
          this.data = r.data
          this.isLoaded = true
          this.displayChart('graphRecentActivity', this.data.recentActivity.chartData)
          this.displayChart('graphSchoolProgress', this.data.schoolProgress.chartData)
        })
        .catch((e) => {
          const data = this.$api.handleFailedRequest(e, true)
          this.validationErrors = data.validationErrors
          this.error = data.error
        })
        .then(() => {
          this.isLoading = false
        })
    },
    displayChart: async function (elementId, data) {
      await sleepForMs(500)
      try {
        const ctx = document.getElementById(elementId)
        var myBarChart = new Chart(ctx, {
          type: 'line',
          data: data,
          options: {
            scales: {
              yAxes: [{
                ticks: {
                  min: 0
                }
              }]
            }
          }
        })
      } catch (e) {
        console.error('Create chart error', e)
      }
    }
  }

})
</script>

<style scoped>
  .school-breakdown-item {
    font-size: 18px;
  }
</style>

<template>

  <div class="row">
    <div class="col s12 center margin-large-top-bottom">
      <img id="photo-fallback"
            src="@/assets/school-placeholder.jpg"
            class="hide" />
      <img :src="schoolPhotoUrl"
            onerror="this.onerror=null;this.src=document.getElementById('photo-fallback').src;"
            class="photo-medium responsive-img circle z-depth-1" />
      <h4 class="dashboard-school-name">{{ schoolName }}</h4>
    </div>

    <div class="col s12">
      <validation-error-display :validationErrors="validationErrors"></validation-error-display>
      <exception-display :error="error"></exception-display>
      <loading-display :isLoading="isLoading"></loading-display>
    </div>
  </div>

  <div class="row" v-if="!isLoading && isLoaded">

    <!-- breakdown -->
    <div class="col s12">
      <div class="card">
        <div class="card-content">

          <span class="card-title padding-medium-bottom">Student Breakdown</span>

          <div>
            <div class="row" style="margin-bottom: 0;">

              <div class="col s12 m6 center-align">
                <div class="left-align" style="display: inline-block;">

                  <!-- ASV -->
                  <p class="school-breakdown-item padding-medium-bottom">
                    <router-link
                      :to="{ name: 'SchoolProgressReport' }"
                      class="blue-text">
                      ${{ data.breakdown.annualStudentValueFormatted }} ASV
                    </router-link>
                  </p>

                  <!-- active trials -->
                  <p class="school-breakdown-item padding-medium-bottom">
                    <router-link
                      :to="{ name: 'Students', params: { filter: 'trials-active' }}"
                      v-bind:class="{ 'green-text': data.breakdown.studentsWithActiveTrial, 'grey-text': !data.breakdown.studentsWithActiveTrial }">{{ data.breakdown.studentsWithActiveTrial }}
                      Active Trials
                    </router-link>
                  </p>

                  <!-- active plans -->
                  <p class="school-breakdown-item padding-medium-bottom">
                    <router-link :to="{ name: 'Students', params: { filter: 'plans-active' }}" v-bind:class="{ 'green-text': data.breakdown.studentsWithActivePlan, 'grey-text': !data.breakdown.studentsWithActivePlan }">
                      {{ data.breakdown.studentsWithActivePlan }} Active Plans</router-link>
                  </p>

                </div>

              </div>

              <div class="col s12 m6 center-align">
                <div class="left-align" style="display: inline-block;">

                  <!-- ended trials -->
                  <p class="school-breakdown-item padding-medium-bottom">
                    <router-link
                      :to="{ name: 'Students', params: { filter: 'trials-ended' }}"
                      v-bind:class="{ 'red-text': data.breakdown.studentsWithEndedTrial, 'grey-text': !data.breakdown.studentsWithEndedTrial }">
                      {{ data.breakdown.studentsWithEndedTrial }} Ended Trials
                    </router-link>
                  </p>

                  <!-- ended plans -->
                  <p class="school-breakdown-item padding-medium-bottom">
                    <router-link
                      :to="{ name: 'Students', params: { filter: 'plans-ended' }}"
                      v-bind:class="{ 'orange-text': data.breakdown.studentsWithEndedPlan, 'grey-text': !data.breakdown.studentsWithEndedPlan }">
                      {{ data.breakdown.studentsWithEndedPlan }} Ended Plans
                    </router-link>
                  </p>

                  <!-- issues -->
                  <p class="school-breakdown-item padding-medium-bottom">
                    <router-link
                      :to="{ name: 'Students', params: { filter: 'issues' }}"
                      v-bind:class="{ 'red-text': data.breakdown.studentsWithIssues, 'grey-text': !data.breakdown.studentsWithIssues }">
                      {{ data.breakdown.studentsWithIssues }} Pay Issues
                    </router-link>
                  </p>

                  <!-- free plans
                  <p class="school-breakdown-item padding-medium-bottom">
                    <router-link
                      :to="{ name: 'Students', params: { filter: 'free-plans' }}"
                      v-bind:class="{ 'orange-text': data.breakdown.studentsWithFreePlans, 'grey-text': !data.breakdown.studentsWithFreePlans }">
                      {{ data.breakdown.studentsWithFreePlans }} Free Plans
                    </router-link>
                  </p>
                  -->

                </div>
              </div>

              <!-- ASV -->
              <div class="col s12 center-align">
                <div class="section grey-text">Annual Student Value (ASV) is re-calculately daily. This is defined as the total income if all students kept renewing their current plans over the period of a year</div>
              </div>

            </div>

          </div>
        </div>
      </div>
    </div>
    <!--/ breakdown -->

    <!-- recent signins -->
    <div class="col s12">
      <div class="card">
        <div class="card-content">

          <span class="card-title">Recent Sign-Ins</span>

          <div class="section">
              <div v-show="hasRecentActivityChartData" class="chart-container" style="position: relative;">
                <canvas id="graphRecentActivity"></canvas>
              </div>
              <p v-show="!hasRecentActivityChartData" class="grey-text">No data available.</p>
          </div>

        </div>
      </div>
    </div>
    <!--/ recent signins -->

    <!-- school progress -->
    <div class="col s12">
      <div class="card">
        <div class="card-content">
          <span class="card-title">School Progress</span>
          <div class="section">
              <div v-show="hasSchoolPrgressChartData" class="chart-container" style="position: relative;">
                <canvas id="graphSchoolProgress"></canvas>
              </div>
              <p v-show="!hasSchoolPrgressChartData" class="grey-text">No data available.</p>
          </div>
        </div>
      </div>
    </div>
    <!--/ school progress -->

  </div>

</template>
