<script>
import { defineComponent } from 'vue'
import ExceptionDisplay from '@/components/ExceptionDisplay.vue'
import ValidationErrorDisplay from '@/components/ValidationErrorDisplay.vue'
import LoadingDisplay from '@/components/LoadingDisplay.vue'

export default defineComponent({
  components: { ExceptionDisplay, ValidationErrorDisplay, LoadingDisplay },
  name: 'StudentPlanPage',
  data () {
    return {
      error: '',
      validationErrors: [],
      isLoading: false,
      studentId: '',
      data: {},
      apiClient: {},
      selectedStudentIdForSharing: ''
    }
  },

  async mounted () {
    this.apiClient = await this.$api.createApiClient()
    this.studentId = this.$route.params.id
    console.debug('studentId', this.studentId)
    // get data
    this.getPlanDetails()
  },

  methods: {
    getPlanDetails: function () {
      this.isLoading = true
      this.error = ''
      this.validationErrors = []
      this.plan = {}
      this.apiClient
        .get('students/' + this.studentId + '/plan')
        .then((r) => {
          console.debug('student plan', JSON.stringify(r.data))
          this.data = r.data
        })
        .catch((e) => this.handleFailedRequest(e))
        .then(() => { this.isLoading = false })
    },
    changePlanStatus: function (newStatusId) {
      this.isLoading = true
      this.error = ''
      this.validationErrors = []
      const data = JSON.stringify({
        newStatus: newStatusId
      })
      this.apiClient
        .put('students/' + this.studentId + '/plan/status', data)
        .then(() => this.getPlanDetails())
        .catch((e) => this.handleFailedRequest(e))
        .then(() => { this.isLoading = false })
    },
    cancelPlan: function () {
      if (!confirm('Are you sure you want to cancel this plan?')) {
        return
      }

      this.isLoading = true
      this.error = ''
      this.validationErrors = []
      this.apiClient
        .delete('students/' + this.studentId + '/plan')
        .then(() => this.getPlanDetails())
        .catch((e) => this.handleFailedRequest(e))
        .then(() => { this.isLoading = false })
    },
    sharePlanWithStudent: function (s) {
      if (!s) {
        return
      }
      console.debug('share', s)
      this.isLoading = true
      this.error = ''
      this.validationErrors = []
      this.apiClient
        .post('students/' + this.studentId + '/plan/share/' + s, JSON.stringify({}))
        .then(() => this.getPlanDetails())
        .catch((e) => this.handleFailedRequest(e))
        .then(() => { this.isLoading = false })
    },
    unsharePlanWithStudent: function (s) {
      if (!s) {
        return
      }
      console.debug('unshare', s)
      this.isLoading = true
      this.error = ''
      this.validationErrors = []
      this.apiClient
        .delete('students/' + this.studentId + '/plan/share/' + s)
        .then(() => this.getPlanDetails())
        .catch((e) => this.handleFailedRequest(e))
        .then(() => { this.isLoading = false })
    },
    goBack: function () {
      this.$router.push('/student/' + this.studentId)
    },
    handleFailedRequest: function (e) {
      const data = this.$api.handleFailedRequest(e, true)
      this.validationErrors = data.validationErrors
      this.error = data.error
    }
  }
})
</script>

<template>

  <div class="row heading center-align" data-test-id="student-plan-page">
    <h4 data-test-id="student-plan-page-student-name">{{ data.studentName }}</h4>
  </div>

  <validation-error-display :validationErrors="validationErrors"></validation-error-display>
  <exception-display :error="error"></exception-display>
  <loading-display :isLoading="isLoading"></loading-display>

  <div v-if="!isLoading" class="row">
    <div class="col s12 l10 offset-l1">

      <div v-if="data.isInheritedPlan">
        <h5>Plan Details</h5>
        <div class="card-panel" data-test-id="student-plan-inherited-details">
          <p class="valign-wrapper">
            <i class="material-icons">info</i>
            &nbsp;Inheriting plan from:&nbsp;<router-link :to="{ name: 'Student', params: { id: data.inheritedPlanStudentId }}">
            {{ data.inheritedPlanStudentName }}
            </router-link></p>
        </div>
      </div>

      <div v-else>

        <!-- trial details -->
        <div v-if="!data.hasAPlan">
          <h5>Trial Details</h5>
          <div class="card-panel" data-test-id="student-trial-details">
            <p class="valign-wrapper">
              <i class="material-icons">info</i>&nbsp;
              <span v-if="data.trialDaysLeft > 0" data-test-id="student-trial-status-active">
                Student is on a trial for another {{ data.trialDaysLeft }} days.
              </span>
              <span v-else data-test-id="student-trial-status-ended">
                Trial has ended.
              </span>
            </p>
          </div>
        </div>

        <!-- plan details -->
        <div v-if="data.subscription" class="row">
          <h5>Plan Details</h5>

          <table class="striped" data-test-id="student-plan-existing-plan-table">
            <tbody>
              <tr>
                <td><label>Plan Name</label></td>
                <td>{{ data.subscription.planName }}</td>
              </tr>
              <tr>
                <td><label>Method</label></td>
                <td>{{ data.subscription.methodLabel }}</td>
              </tr>
              <tr v-if="data.subscription.setupFee">
                <td><label>Setup fee</label></td>
                <td>${{ data.subscription.setupFee }}</td>
              </tr>
              <tr>
                <td><label>Price</label></td>
                <td>${{ data.subscription.price }}</td>
              </tr>
              <tr>
                <td><label>Frequency</label></td>
                <td>{{ data.subscription.frequencyLabel }}</td>
              </tr>
              <tr>
                <td><label>Contract Started</label></td>
                <td>{{ data.subscription.contractStartedLabel }}</td>
              </tr>
              <tr>
                <td><label>Contract Ends</label></td>
                <td>{{ data.subscription.contractEndsLabel }}</td>
              </tr>
              <tr>
                <td><label>Status</label></td>
                <td>

                  <div>
                    <div v-if="data.subscription.status === 1" class="section valign-wrapper">
                      <i class="material-icons green-text">check_circle</i>
                      <span class="margin-medium green-text" data-test-id="plan-status-label">{{ data.subscription.statusLabel }}</span>
                    </div>
                    <div v-else class="section valign-wrapper">
                      <i class="material-icons red-text">warning</i>
                      <span class="margin-medium red-text" data-test-id="plan-status-label">{{ data.subscription.statusLabel }}</span>
                    </div>
                  </div>

                  <div v-if="data.subscription.status === 3">
                    <div v-if="data.subscription.processingError">
                      <blockquote>
                          {{ data.subscription.processingError }}
                      </blockquote>
                    </div>

                    <ul v-if="data.subscription.paymentMethod === 2">
                      <li>Note that for any dishonored payments, Ezidebit will try take the payment again in 3 business days.</li>
                      <li>You should log in to Ezidebit and fix the issue if you don't want to wait.</li>
                    </ul>

                    <div v-if="data.canEditPlan" class="margin-medium-left" data-test-id="action-resolve-payment-issue">
                      <a @click="changePlanStatus(1)" class="btn blue">Set as Resolved</a><br/>
                      <span class="grey-text valign-wrapper"><i class="material-icons margin-medium-right">help</i> Clicking this will allow the student to sign in until the next payment failure</span>
                    </div>

                  </div>

                  <div v-else-if="data.subscription.status === 1">
                      <div v-if="data.canEditPlan" class="margin-medium-left">
                        <a @click="changePlanStatus(3)" class="btn blue" data-test-id="action-set-payment-issue">Set as Payment Issue</a><br/>
                        <span class="grey-text valign-wrapper"><i class="material-icons margin-medium-right">help</i> Clicking this button will prevent the student from signing in until the next payment success</span>
                      </div>
                  </div>

                </td>
              </tr>
              <tr v-if="data.canSharePlan">
                <td><label>Plan Sharing</label></td>
                <td>
                  <div v-if="data.sharedWithStudents?.length > 0">
                    This plan is currently being shared to:
                    <ul class="browser-default" data-test-id="existing-shares">
                      <li v-for="s in data.sharedWithStudents" :key="s.id" :data-test-id="s.fullName">
                        <router-link :to="{ name: 'Student', params: { id: s.id }}">
                          <span data-test-id="existing-share-fullname-label">{{ s.fullName }}</span>
                        </router-link>
                        <a @click="unsharePlanWithStudent(s.id)" class="btn red margin-medium-left" data-test-id="action-remove-share-to-student">Unshare</a>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <span data-test-id="label-shares-remaining">{{ data.countOfSharesLeft }}</span> share(s) remaining
                  </div>
                  <div v-if="data.countOfSharesLeft > 0">

                    <div class="input-field">
                      <select @change="selectedStudentIdForSharing = $event.target.value" class="browser-default" data-test-id="dropdown-share-to-student-options">
                        <option value="" selected="selected">Choose a student..</option>
                        <option v-for="s in data.allStudentsForSharing" :key="s.id" :value="s.id" :data-test-id="s.id">{{ s.fullName }}</option>
                      </select>
                    </div>

                    <div class="margin-medium-top-bottom">
                      <a v-if="selectedStudentIdForSharing.length > 0" @click="sharePlanWithStudent(selectedStudentIdForSharing)" class="btn blue" data-test-id="action-create-share-to-student">Share</a>
                      <a v-else disabled="disabled" class="btn blue">Share</a>
                    </div>

                    <div class="grey-text">
                      <div class="valign-wrapper"><i class="material-icons margin-medium-right">help</i> For a student to appear on the above list, they must:</div>
                      <ul class="browser-default">
                        <li>Be on a trial (active or ended) / an ended plan</li>
                        <li>Are not already being shared to</li>
                      </ul>
                    </div>

                  </div>
                </td>
              </tr>
              <tr v-if="data.canCancelPlan">
                <td><label>Actions</label></td>
                <td>
                  <button @click="cancelPlan()" class="btn red" data-test-id="plan-end-button">End Plan</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- plan selection -->
        <div v-if="data.canStartNewPlans" class="row">

          <h5>Choose a plan</h5>

          <div v-if="!data.availablePlans">
            No plans found, create some <router-link to="/plans" class="btn blue">here</router-link>
          </div>

          <!-- available plans select -->
          <div v-for="p in data.availablePlans" :key="p.planId" class="col s12 m12 l6">
              <div class="card white" data-test-id="student-plan-available-plan">
                  <div class="card-content white black-text">
                    <span class="card-title">{{ p.planName }}</span>
                  </div>
                  <div class="card-action">
                    <router-link v-if="data.canStartNewPlans" :to="{ name: 'StudentPlanApply', params: { id: this.studentId, planId: p.planId }}" class="btn blue" :data-test-id="p.planName">
                      Activate
                    </router-link>
                  </div>
              </div>
          </div>

        </div>

      </div>

      <div class="row">
        <div class="right">
          <a class="btn grey" @click="goBack()">Back</a>
        </div>
      </div>

    </div>

  </div>
</template>
