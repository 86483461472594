
<script>
import { defineComponent } from 'vue'
import ExceptionDisplay from '@/components/ExceptionDisplay.vue'
import ValidationErrorDisplay from '@/components/ValidationErrorDisplay.vue'
import LoadingDisplay from '@/components/LoadingDisplay.vue'

const vmNewPlanDefinition = {
  name: '',
  isDisabled: false,
  isAllClassesSelected: true,
  classes: [],
  classIds: []
}

export default defineComponent({
  components: { ExceptionDisplay, ValidationErrorDisplay, LoadingDisplay },
  name: 'PlansPage',
  data () {
    return {
      isLoading: false,
      allPlans: [],
      allClasses: [],
      selectedPlan: null,
      error: '',
      validationErrors: [],
      apiClient: {}
    }
  },

  async mounted () {
    this.apiClient = await this.$api.createApiClient()
    this.getAllPlans()
    this.getAllClasses()
  },

  methods: {
    getAllPlans: function () {
      this.isLoading = true
      this.allPlans = []
      this.selectedPlan = null
      this.error = ''
      this.validationErrors = []
      this.apiClient
        .get('plans')
        .then((r) => {
          this.allPlans = r.data
          console.debug('Plans', r.data)
        })
        .catch((e) => {
          const status = e.status || (e.response ? e.response.status : 0)
          if (status === 401) {
            this.$api.handleLoginExpired()
          } else {
            this.handleError(e)
          }
        })
        .then(() => {
          this.isLoading = false
        })
    },
    getAllClasses: function () {
      this.allClasses = []
      this.error = ''
      this.validationErrors = []
      this.apiClient
        .get('classes')
        .then((r) => {
          this.allClasses = r.data
          console.debug('Classes', r.data)
        })
        .catch((e) => {
          const status = e.status || (e.response ? e.response.status : 0)
          if (status === 401) {
            this.$api.handleLoginExpired()
          } else {
            this.handleError(e)
          }
        })
    },
    deletePlan: function (planId) {
      if (!confirm('Are you sure you want to delete this plan?')) {
        return
      }

      console.debug('deleting', planId)

      this.validationErrors = []
      this.isLoading = true
      this.apiClient
        .delete('plans/' + planId)
        .then(() => {
          // refresh
          this.getAllPlans()
        })
        .catch((e) => {
          const status = e.status || (e.response ? e.response.status : 0)
          console.debug('error', status)
          if (status === 401) {
            this.$api.handleLoginExpired()
          } else if (status === 400) {
            this.handleBadRequest(e.response)
          } else {
            this.handleError(e)
          }
        })
        .then(() => {
          this.isLoading = false
        })
    },
    openEditForm: function (item) {
      this.selectedPlan = item
    },
    openNewForm: function () {
      const template = Object.assign({}, vmNewPlanDefinition)
      this.selectedPlan = template
      var classesCopy = JSON.parse(JSON.stringify(this.allClasses))
      this.selectedPlan.classes = classesCopy
    },
    savePlan: function () {
      if (!this.selectedPlan) {
        return
      }

      this.validationErrors = []
      this.isLoading = true

      // decide api method
      var relativeUri = 'plans'

      // if has Id then it already exists
      if (this.selectedPlan.id) {
        relativeUri += '/' + this.selectedPlan.id
      }

      // map class ids
      this.selectedPlan.classes = this.selectedPlan.classes || []
      this.selectedPlan.classIds = this.selectedPlan.classes.filter((c) => c.isSelected).map((c) => c.id)

      // no classes selected means full class access
      if (this.selectedPlan.isAllClassesSelected) {
        this.selectedPlan.classIds = []
      }

      this.apiClient
        .post(relativeUri, JSON.stringify(this.selectedPlan))
        .then(() => {
          // refresh
          this.getAllPlans()
        })
        .catch((e) => {
          const status = e.status || (e.response ? e.response.status : 0)
          console.debug('error', status)
          if (status === 401) {
            this.$api.handleLoginExpired()
          } else if (status === 400) {
            this.handleBadRequest(e.response)
          } else {
            this.handleError(e)
          }
        })
        .then(() => {
          this.isLoading = false
        })
    },
    clear: function () {
      this.selectedPlan = null
    },
    handleBadRequest: function (e) {
      console.debug('e', e)
      this.validationErrors = e.data.errors
    },
    handleError: function (e) {
      this.error = e.message
    }
  }
})
</script>

<template>

  <div class="row heading">
    <h4>Plans</h4>
    <p class="grey-text">Plans let you control class access</p>
  </div>

  <validation-error-display :validationErrors="validationErrors"></validation-error-display>
  <exception-display :error="error"></exception-display>
  <loading-display :isLoading="isLoading"></loading-display>

  <div v-if="!isLoading">

    <div v-if="!selectedPlan">
      <!-- list -->
      <div>
        <a class="btn blue" @click="openNewForm()">Create</a>
      </div>
      <table class="striped">
        <tbody>
          <tr v-for="p in allPlans" v-bind:key="p.id">
              <td>
                  <p class="margin-medium"><strong>{{ p.name }}</strong> <span v-if="p.isDisabled" class="grey-text">(disabled)</span></p>
                  <ul class="browser-default" v-if="p.isAllClassesSelected">
                    <li v-for="c in p.classes" :key="c.id">{{ c.name }}</li>
                  </ul>
                  <ul class="browser-default" v-else>
                    <li v-for="c in p.classes.filter((x) => x.isSelected)" :key="c.id">{{ c.name }} <span v-if="c.isDisabled" class="grey-text">(disabled)</span></li>
                  </ul>
              </td>
              <td class="valign-wrapper section button-strip">
                <a class="btn blue" @click="openEditForm(p)">Edit</a>
                <a v-if="p.id" class="btn red" @click="deletePlan(p.id)">Delete</a>
              </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- create/edit form -->
    <div v-if="selectedPlan">
      <div>
        <strong>Name</strong>
        <input type="text" v-model="selectedPlan.name" />
      </div>
      <div class="section">
        <label>
          <input type="checkbox" class="filled-in" v-model="selectedPlan.isDisabled" />
          <span>Is Disabled</span>
        </label>
      </div>
      <div class="section">
        <strong>Class access for plan</strong>
        <div class="section">
          <label>
            <input type="checkbox" class="filled-in" v-model="selectedPlan.isAllClassesSelected" />
            <span class="green-text">ALL CLASSES</span>
          </label>
        </div>
        <div class="section" v-show="!selectedPlan.isAllClassesSelected" v-for="c in selectedPlan.classes" :key="c">
          <label>
            <input type="checkbox" class="filled-in" v-model="c.isSelected" />
            <span>{{ c.name }} <span v-if="c.isDisabled" class="grey-text">(disabled)</span></span>
          </label>
        </div>
      </div>
      <div class="button-strip">
        <a class="btn blue" @click="savePlan()">{{ selectedPlan.id ? 'Save' : 'Create' }}</a>
        <a class="btn grey" @click="clear()">Cancel</a>
      </div>
    </div>

  </div>

</template>
