<script lang="ts">
export default {
  props: {
    validationErrors: Array
  }
}
</script>

<template>
  <div v-if="validationErrors.length > 0" class="row">
    <div class="error-message orange-text text-darken-4 orange lighten-5
">
      <p v-for="e in validationErrors" v-bind:key="e" class="valign-wrapper">
        <i class="material-icons">error</i>
        <span data-test-id="generic-validation-error-label">{{ e }}</span>
      </p>
      <p class="margin-medium-left">
        If this doesn't seem right, please <a class="" href="https://m.me/ossgym" target="_blank">get in touch</a>
      </p>
    </div>
  </div>
</template>
