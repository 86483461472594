<script>
import { defineComponent } from 'vue'
import ExceptionDisplay from '@/components/ExceptionDisplay.vue'
import LoadingDisplay from '@/components/LoadingDisplay.vue'
import ValidationErrorDisplay from '@/components/ValidationErrorDisplay.vue'

export default defineComponent({
  components: { ExceptionDisplay, LoadingDisplay, ValidationErrorDisplay },
  name: 'StudentDeletePage',
  data () {
    return {
      isLoading: true,
      error: '',
      validationErrors: [],
      studentId: '',
      studentName: '',
      hasPaymentPlanLink: false,
      cancelPaymentPlan: false,
      apiClient: {}
    }
  },

  async mounted () {
    this.apiClient = await this.$api.createApiClient()
    this.studentId = this.$route.params.id
    console.debug('studentId', this.studentId)
    // get data
    this.loadForm()
  },

  methods: {
    loadForm: function () {
      this.isLoading = true
      this.error = ''
      this.validationErrors = []
      this.apiClient
        .get('students/' + this.studentId + '/delete')
        .then((r) => {
          this.studentName = r.data.studentFullName
          this.hasPaymentPlanLink = r.data.hasPaymentPlanLink
        })
        .catch(e => this.onRequestFailure(e))
        .then(() => { this.isLoading = false })
    },
    confirmDelete: function () {
      this.isLoading = true
      this.error = ''
      this.apiClient
        .delete('students/' + this.studentId + '/?cancelPlanToo=' + this.cancelPaymentPlan)
        .then(() => this.$router.push('/students'))
        .catch(e => this.onRequestFailure(e))
        .then(() => { this.isLoading = false })
    },
    goBack: function () {
      this.$router.push('/student/' + this.studentId)
    },
    onRequestFailure: function (e) {
      const status = e.status || (e.response ? e.response.status : 0)
      if (status === 401) {
        this.$api.handleLoginExpired()
      } else if (status === 400) {
        this.validationErrors = e.response.data?.errors || 'There was an unexpected error'
      } else {
        this.error = 'There was an unexpected error'
      }
    }
  }
})
</script>

<template>

<div class="row heading" data-test-id="page-student-delete">
    <h4>Delete Student</h4>
</div>

  <exception-display :error="error"></exception-display>
  <validation-error-display :validationErrors="validationErrors"></validation-error-display>
  <loading-display :isLoading="isLoading"></loading-display>

  <div v-if="!isLoading">

    <div class="row section">
      <div class="col s12 center-align">
        <div class="section">

            <h4 data-test-id="student-delete-name-label">{{ studentName }}</h4>

            <p>Are you sure you want to delete this student?</p>

        </div>
      </div>

      <div class="col s12 m6 center-align">
        <div class="section">
          <p>
              Alternatively, you could Disable the student so that they cannot sign in.
              Later, if you want to Enable the student again, you can.
          </p>
          <router-link :to="{ name: 'Student', params: { id: studentId }}">Disable instead</router-link>
        </div>
      </div>

      <div class="col s12 m6 center-align">
        <div class="section">
          <div v-if="hasPaymentPlanLink">
            <p>
              <strong>Warning:</strong> This student has an external payment plan linked to Oss, would you also like to cancel that too?
              If you don't then you'll need to log in to your payment provider and do this manually.
            </p>
            <p>
              <label data-test-id="student-delete-cancel-payment-plan-checkbox">
                <input v-model="cancelPaymentPlan" type="checkbox" class="filled-in"/>
                <span>Cancel payment plan</span>
              </label>
            </p>
          </div>
          <a @click="confirmDelete()" class="btn red" data-test-id="student-delete-confirm-button">Delete</a>
        </div>
      </div>

    </div>

  </div>
</template>
