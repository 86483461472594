<script>
import { defineComponent } from 'vue'
import ExceptionDisplay from '@/components/ExceptionDisplay.vue'
import LoadingDisplay from '@/components/LoadingDisplay.vue'

export default defineComponent({
  components: { ExceptionDisplay, LoadingDisplay },
  name: 'AttentionPage',
  data () {
    return {
      error: '',
      isLoading: false,
      apiClient: {},
      items: []
    }
  },

  async created () {
    this.apiClient = await this.$api.createApiClient()
    this.loadData()
  },

  methods: {
    loadData: function () {
      this.error = ''
      this.isLoading = true
      this.items = []
      this.apiClient
        .get('attention')
        .then((r) => {
          this.items = r.data
        })
        .catch((e) => {
          const status = e.status || (e.response ? e.response.status : 0)
          if (status === 401) {
            this.$api.handleLoginExpired()
          } else {
            this.handleError(e)
          }
        })
        .then(() => {
          this.isLoading = false
        })
    },
    handleError: function (e) {
      this.error = e.message
    }
  }

})
</script>

<style scoped>
</style>

<template>

  <div class="row heading">
    <h4>Attention</h4>
    <p class="grey-text">Students that may require following up</p>
  </div>

  <exception-display :error="error"></exception-display>
  <loading-display :isLoading="isLoading"></loading-display>

  <div v-if="!isLoading && items.length === 0">
    <p>Nothing to do!</p>
  </div>

  <div v-if="!isLoading && items.length > 0">
    <table>
      <tr v-for="s in items" v-bind:key="s.studentId">
        <td>
          <i class="material-icons grey-text">person</i>
        </td>
        <td>
          <router-link :to="{ name: 'Student', params: { id: s.studentId }}">
            {{ s.studentName }}
          </router-link>
        </td>
        <td>
          <div v-for="i in s.issues" :key="i">{{ i }}</div>
        </td>
      </tr>
    </table>
  </div>

</template>
