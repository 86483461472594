import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueGtag from 'vue-gtag-next'
import api from '@/data/ApiLayer1'

(async () => {
  // await api.init() // doesn't exist in ApiLayer1, but needed for ApiLayer2

  const app = createApp(App)
  app.use(store)
  app.use(router)

  const enableGa = JSON.stringify(process.env.VUE_APP_INJECT_GA) === JSON.stringify('true')

  app.use(VueGtag, {
    isEnabled: enableGa,
    property: {
      id: 'G-EH4VCTD03C'
    }
  })

  api.setAnalyticsUserId()
  app.config.globalProperties.$api = api
  app.mount('#app')
})()
