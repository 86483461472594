<script>
import { defineComponent } from 'vue'

export default defineComponent({
  components: { },
  name: 'LogoutPage',
  data () {
    return {
    }
  },

  methods: {
  }

})
</script>

<template>
  <div class="row" data-test-id="page-logged-out">
    <div class="col s12 l6 offset-l3 center-align">
      <div>
        <div class="card-panel">
          <h4>Logged out</h4>
          <p>
          <router-link to="/login" class="btn blue" data-test-id="log-back-in-nav">
            Log back in!
          </router-link>
        </p>
        </div>
      </div>
    </div>
  </div>

</template>
