<script>
import { defineComponent } from 'vue'
import StudentDetailForm from '@/components/StudentDetailForm.vue'
import StudentEditForm from '@/components/StudentEditForm.vue'
import ExceptionDisplay from '@/components/ExceptionDisplay.vue'
import ValidationErrorDisplay from '@/components/ValidationErrorDisplay.vue'
import LoadingDisplay from '@/components/LoadingDisplay.vue'

function zeroPad (n) { return ('0' + n).slice(-2) }

const elementIdSignInModal = '#signInModal'
const elementIdEmailModal = '#emailModal'
const elementIdEmailBody = '#emailBody'

export default defineComponent({
  name: 'StudentDetailsPage',
  components: {
    StudentDetailForm,
    StudentEditForm,
    ExceptionDisplay,
    ValidationErrorDisplay,
    LoadingDisplay
  },
  data () {
    return {
      error: '',
      validationErrors: [],
      isLoading: false,
      studentId: '',
      student: {},
      studentFullName: '',
      studentHasPhoto: true,
      isEditMode: false,
      signInModal: {
        isLoading: false,
        availableClasses: [],
        signInDate: '',
        signInTime: '',
        validationErrors: [],
        error: '',
        reset: function (resetFields) {
          this.isLoading = false
          this.error = ''
          this.validationErrors = []
          if (resetFields) {
            const today = new Date()
            this.signInDate = today.getFullYear() + '-' + zeroPad(today.getMonth() + 1) + '-' + zeroPad(today.getDate())
            this.signInTime = today.getHours() + ':' + today.getMinutes()
            this.availableClasses = []
          }
        }
      },
      emailModal: {
        isLoading: false,
        title: '',
        body: '',
        validationErrors: [],
        error: '',
        reset: function (resetFields) {
          this.isLoading = false
          this.error = ''
          this.validationErrors = []
          if (resetFields) {
            this.title = ''
            this.body = ''
          }
        }
      },
      apiClient: {}
    }
  },

  async mounted () {
    this.apiClient = await this.$api.createApiClient()
    this.studentId = this.$route.params.id
    console.debug('studentId', this.studentId)
    document.runMaterializeInit() // fix for modal init issue
    // get data
    this.getStudentDetails()
  },

  methods: {
    getStudentDetails: function () {
      this.isLoading = true
      this.error = ''
      this.validationErrors = []
      this.student = {}
      this.studentHasPhoto = true // reset this to try load image again
      this.isEditMode = false
      this.apiClient
        .get('students/' + this.studentId)
        .then((r) => {
          console.debug('student', r.data)
          this.student = r.data
          this.studentFullName = r.data.model.firstName + ' ' + r.data.model.lastName
        })
        .catch((e) => {
          const status = e.status || (e.response ? e.response.status : 0)
          if (status === 401) {
            this.$api.handleLoginExpired()
          } else if (status === 400) {
            this.handleBadRequest(e.response)
          } else {
            this.handleError(e)
          }
        })
        .then(() => {
          this.isLoading = false
        })
    },
    save: function () {
      this.isLoading = true
      this.error = ''
      this.validationErrors = []
      const data = JSON.stringify(this.student.model)
      this.apiClient
        .post('students/' + this.studentId, data)
        .then(() => {
          // refresh
          this.getStudentDetails()
        })
        .catch((e) => {
          const status = e.status || (e.response ? e.response.status : 0)
          if (status === 401) {
            this.$api.handleLoginExpired()
          } else if (status === 400) {
            this.handleBadRequest(e.response)
          } else {
            this.handleError(e)
          }
        })
        .then(() => {
          this.isLoading = false
        })
    },
    goBack: function () {
      this.$router.go(-1)
    },
    openPhotoUploadPrompt: function () {
      document.getElementById('ImageUpload').click()
    },
    uploadImage: function (event) {
      this.error = ''
      this.validationErrors = []
      this.isLoading = true
      const data = new FormData()
      data.append('file', event.target.files[0])
      const config = {
        header: {
          'Content-Type': 'image/png'
        }
      }
      // upload
      this.apiClient
        .post('students/' + this.studentId + '/photo', data, config)
        .then(() => {
          this.getStudentDetails()
        })
        .catch((e) => {
          const status = e.status || (e.response ? e.response.status : 0)
          console.debug('status', status)
          if (status === 401) {
            this.$api.handleLoginExpired()
          } else if (status === 400) {
            this.handleBadRequest(e.response)
          } else {
            this.handleError(e)
          }
        })
        .then(() => {
          this.isLoading = false
        })
    },
    uploadConsentPhoto: function (event) {
      this.error = ''
      this.validationErrors = []
      this.isLoading = true
      const data = new FormData()
      data.append('file', event.target.files[0])
      const config = {
        header: {
          'Content-Type': 'image/png'
        }
      }
      // upload
      this.apiClient
        .post('students/' + this.studentId + '/document', data, config)
        .then(() => {
          this.getStudentDetails()
        })
        .catch((e) => {
          const status = e.status || (e.response ? e.response.status : 0)
          console.debug('status', status)
          if (status === 401) {
            this.$api.handleLoginExpired()
          } else if (status === 400) {
            this.handleBadRequest(e.response)
          } else {
            this.handleError(e)
          }
        })
        .then(() => {
          this.isLoading = false
        })
    },
    deleteImage: function () {
      if (!confirm('Are you sure you want to delete this image?')) {
        return
      }
      this.isLoading = true
      this.error = ''
      this.validationErrors = []
      this.apiClient
        .delete('students/' + this.studentId + '/photo')
        .then(() => {
          this.getStudentDetails()
        })
        .catch((e) => {
          const status = e.status || (e.response ? e.response.status : 0)
          console.debug('status', status)
          if (status === 401) {
            this.$api.handleLoginExpired()
          } else if (status === 400) {
            this.handleBadRequest(e.response)
          } else {
            this.handleError(e)
          }
        })
        .then(() => {
          this.isLoading = false
        })
    },
    deleteConsentPhoto: function () {
      if (!confirm('Are you sure you want to delete this image?')) {
        return
      }
      this.isLoading = true
      this.error = ''
      this.validationErrors = []
      this.apiClient
        .delete('students/' + this.studentId + '/document')
        .then(() => {
          this.getStudentDetails()
        })
        .catch((e) => {
          const status = e.status || (e.response ? e.response.status : 0)
          console.debug('status', status)
          if (status === 401) {
            this.$api.handleLoginExpired()
          } else if (status === 400) {
            this.handleBadRequest(e.response)
          } else {
            this.handleError(e)
          }
        })
        .then(() => {
          this.isLoading = false
        })
    },
    openSignInModal: function () {
      this.signInModal.reset(true)
      this.signInModal.isLoading = true

      document.openModal(elementIdSignInModal)
      this.apiClient
        .get('students/' + this.studentId + '/manualSignIn')
        .then((r) => {
          // console.debug('r', r.data)
          this.signInModal.availableClasses = r.data
        })
        .catch((e) => {
          const status = e.status || (e.response ? e.response.status : 0)
          if (status === 401) {
            this.$api.handleLoginExpired()
          } else {
            this.signInModal.error = 'There was an error trying to get this data (status code: ' + status + ')'
          }
        })
        .then(() => {
          this.signInModal.isLoading = false
        })
    },
    doManualSignIn: function () {
      const selectedClassIds = this.signInModal.availableClasses
        .filter((c) => c.selected)
        .map((c) => c.id)

      const data = JSON.stringify({
        selectedClassIds: selectedClassIds,
        date: this.signInModal.signInDate,
        time: this.signInModal.signInTime
      })

      this.signInModal.isLoading = true
      this.signInModal.reset(false)

      this.apiClient
        .post('students/' + this.studentId + '/manualSignIn', data)
        .then(() => {
          document.closeModal(elementIdSignInModal)
          document.popToast('Sign in success!')
          this.getStudentDetails()
        })
        .catch((e) => {
          const status = e.status || (e.response ? e.response.status : 0)
          console.debug('error', status)
          if (status === 401) {
            this.$api.handleLoginExpired()
          } else if (status === 400) {
            if (e.response.data.Message) {
              this.signInModal.validationErrors = [e.response.data.Message]
            } else {
              this.signInModal.validationErrors = e.response.data.errors
            }
          } else {
            const errString = JSON.stringify(e)
            console.debug('api error', errString)
            this.signInModal.error = e.message
          }
        })
        .then(() => {
          this.signInModal.isLoading = false
        })
    },
    openEmailModal: function () {
      this.emailModal.reset(true)
      this.emailModal.body = 'Hi ' + this.studentFullName + ',\n'
      document.openModal(elementIdEmailModal)
      document.textareaAutoResize(elementIdEmailBody)
    },
    sendEmail: function () {
      const data = JSON.stringify({
        title: this.emailModal.title,
        body: this.emailModal.body
      })

      this.emailModal.reset(false)
      this.emailModal.isLoading = true

      this.apiClient
        .post('students/' + this.studentId + '/email', data)
        .then(() => {
          document.closeModal(elementIdEmailModal)
          document.popToast('Email sent!')
        })
        .catch((e) => {
          const status = e.status || (e.response ? e.response.status : 0)
          console.debug('error', status)
          if (status === 401) {
            this.$api.handleLoginExpired()
          } else if (status === 400) {
            if (e.response.data.Message) {
              this.emailModal.validationErrors = [e.response.data.Message]
            } else {
              this.emailModal.validationErrors = e.response.data.errors
            }
          } else {
            const errString = JSON.stringify(e)
            console.debug('api error', errString)
            this.emailModal.error = e.message
          }
        })
        .then(() => {
          this.emailModal.isLoading = false
        })
    },
    handleBadRequest: function (e) {
      if (e.data.Message) {
        this.validationErrors = [e.data.Message]
      } else {
        this.validationErrors = e.data.errors
      }
    },
    handleError: function (e) {
      const errString = JSON.stringify(e)
      console.debug('api error', errString)
      this.error = e.message
    },
    cacheBusterChars: function () {
      return (Math.random() + '').substring(2, 6)
    }
  }
})
</script>

<template>

  <div data-test-id="page-student-details">
    <validation-error-display :validationErrors="validationErrors"></validation-error-display>
    <exception-display :error="error"></exception-display>
    <loading-display :isLoading="isLoading"></loading-display>
  </div>

  <div v-if="!isLoading && student.id">

    <div class="row"></div>

    <div class="row">
      <div class="col s12 center-align">
        <div>
          <img v-if="!studentHasPhoto" src="@/assets/student-placeholder.jpg"
              class="responsive-img circle z-depth-1 photo" />
          <img v-if="studentHasPhoto" :src="student.photoUrl + '?' + cacheBusterChars()"
              @error="studentHasPhoto = false"
              class="responsive-img circle z-depth-1 photo" />
        </div>
        <div>
        </div>

        <h4 data-test-id="student-detail-fullname">{{ studentFullName }}</h4>

        <div class="button-strip">
          <input class="hide" id="ImageUpload" name="ImageUpload" type="file" accept=".jpeg, .jpg, .gif, .png" @change="uploadImage($event)" data-test-id="photo-upload-input" />
          <a class="btn blue" @click="openPhotoUploadPrompt()">Upload Photo</a>
          <a class="btn red" @click="deleteImage()" data-test-id="photo-delete-button">Delete Photo</a>
        </div>
      </div>
    </div>

    <div class="row center-align">
      <div v-if="!isEditMode" class="button-strip">
        <a class="btn blue" @click="isEditMode = !isEditMode" href="#top">Edit</a>
        <router-link :to="{ name: 'StudentPlan', params: { id: student.id }}" class="btn blue" data-test-id="student-plan-nav-button">Plan</router-link>
        <router-link :to="{ name: 'AttendenceReport', params: { studentId: student.id }}" class="btn blue">Attendence</router-link>
        <a class="btn blue modal-trigger" @click="openSignInModal()">Manual Sign In</a>
        <a class="btn blue modal-trigger" @click="openEmailModal()">Send Email</a>
        <router-link :to="{ name: 'StudentDelete', params: { id: student.id }}" class="btn red" data-test-id="student-delete-nav-button">Delete</router-link>
        <a class="btn grey" @click="goBack()">Back</a>
      </div>
    </div>

    <div v-if="isEditMode" class="row">
      <div v-if="isEditMode" class="button-strip center-align">
        <a class="btn blue" @click="save()">Save</a>
        <a class="btn grey" @click="isEditMode = !isEditMode; getStudentDetails()">Cancel</a>
      </div>

      <student-edit-form :studentRef="student.model" />

      <div v-if="isEditMode" class="button-strip center-align">
        <a class="btn blue" @click="save()">Save</a>
        <a class="btn grey" @click="isEditMode = !isEditMode; getStudentDetails()">Cancel</a>
      </div>
    </div>

    <div v-if="!isEditMode" class="row">
      <div class="col s12 l10 offset-l1">
          <student-detail-form :student="student" />
      </div>
    </div>

    <div v-if="!isEditMode" class="row">
      <div class="col s12 l10 offset-l1 center-align">

        <div class="section grey lighten-4">
          <p><strong>Consent Document</strong></p>

          <div v-if="student.consentPhotoUrl">
            <a v-bind:href="student.consentPhotoUrl" target="_blank">
                <img v-bind:src="student.consentPhotoUrl" class="responsive-img z-depth-1 photo" style="max-width: 100px;" />
            </a>
            <div class="section">
              <a @click="deleteConsentPhoto()" class="btn red" data-test-id="document-delete-button">Delete</a>
            </div>
          </div>

          <div class="file-field" style="width: 98px; display: inline-block;">
            <div class="btn blue">
              <span>Upload</span>
              <input class="text-box single-line" id="ConsentUpload" name="ConsentUpload" type="file" accept=".jpeg, .jpg, .gif, .png" @change="uploadConsentPhoto($event)"  data-test-id="document-upload-input" />
            </div>
            <div class="file-path-wrapper">
              <input class="file-path validate" type="text">
            </div>
          </div>

        </div>

      </div>

    </div>

  </div>

  <!-- signin modal -->
  <div id="signInModal" class="modal">
    <div class="modal-content">

      <div class="col s12 center-align">
        <h4>Manual Sign In</h4>
        <h6>{{ studentFullName }}</h6>
      </div>

      <loading-display :isLoading="signInModal.isLoading"></loading-display>
      <validation-error-display :validationErrors="signInModal.validationErrors"></validation-error-display>
      <exception-display :error="signInModal.error"></exception-display>

      <div v-if="!signInModal.isLoading">

        <div class="col s12 l6">
          <div class="center-align">
            <p>Select time</p>
            <div class="left-align" style="display: inline-block;">
              <div class="input-field">
                <input v-model="signInModal.signInDate" type="date" />
              </div>
              <div class="input-field">
                <input v-model="signInModal.signInTime" type="time" />
              </div>
            </div>
          </div>
        </div>

        <div class="col s12 l6">
          <div class="center-align">
          <p>Select classes</p>
          <div class="left-align" style="display: inline-block;">
            <div v-for="c in signInModal.availableClasses" :key="c.id">
              <label>
                <input type="checkbox" v-model="c.selected" class="filled-in" />
                <span class="black-text">{{ c.name }}</span>
              </label>
            </div>
          </div>
          </div>
        </div>
      </div>

    </div>

    <div v-if="!signInModal.isLoading" class="modal-footer">
      <div class="button-strip">
        <a @click="doManualSignIn()" class="btn blue">Sign In</a>
        <a href="#!" class="modal-close btn grey">Cancel</a>
      </div>
    </div>
  </div>
  <!-- sign in modal -->

  <!-- email modal -->
  <div id="emailModal" class="modal">
    <div class="modal-content">

      <div class="col s12 center-align">
        <h4>Custom Email</h4>
        <h6>{{ studentFullName }}</h6>
      </div>

      <loading-display :isLoading="emailModal.isLoading"></loading-display>
      <validation-error-display :validationErrors="emailModal.validationErrors"></validation-error-display>
      <exception-display :error="emailModal.error"></exception-display>

      <div v-show="!emailModal.isLoading">
        <div class="col s12">
          <div class="input-field">
            <strong>Title</strong>
            <input v-model="emailModal.title" class="counted" type="text" data-length="70" />
          </div>
          <div class="input-field">
            <strong>Body</strong>
              <textarea v-model="emailModal.body" id="emailBody" class="materialize-textarea counted" style="min-height:66px;"></textarea>
          </div>
        </div>
      </div>

    </div>

    <div v-if="!emailModal.isLoading" class="modal-footer button-strip">
      <a @click="sendEmail()" class="btn blue">Send</a>
      <a href="#!" class="modal-close btn grey">Cancel</a>
    </div>
  </div>
  <!-- sign in modal -->

</template>
