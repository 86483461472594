<script lang="ts">
export default {
  props: {
    isLoading: Boolean,
    isWhite: Boolean
  }
}
</script>

<template>
  <div v-if="isLoading" class="row">
    <div class="center">
      <div :class="{ 'lds-dual-ring-white': isWhite, 'lds-dual-ring': !isWhite }"></div>
    </div>
  </div>
</template>
