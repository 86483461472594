<script>
import { defineComponent } from 'vue'
import ExceptionDisplay from '@/components/ExceptionDisplay.vue'
import ValidationErrorDisplay from '@/components/ValidationErrorDisplay.vue'
import LoadingDisplay from '@/components/LoadingDisplay.vue'

const vmNewUserDefinition = {
  firstName: '',
  lastName: '',
  email: ''
}

export default defineComponent({
  components: { ExceptionDisplay, ValidationErrorDisplay, LoadingDisplay },
  name: 'UsersPage',
  data () {
    return {
      isLoading: false,
      error: '',
      validationErrors: [],
      data: {},
      newUser: Object.assign({}, vmNewUserDefinition)
    }
  },

  async mounted () {
    await this.getAllUsers()
  },

  computed: {
    remainingUserSlots () {
      return this.data.maxUsersAllowed - (this.data.users || []).length
    }
  },

  methods: {
    getAllUsers: async function () {
      this.isLoading = true
      this.error = ''
      this.validationErrors = []
      this.data = {}
      var apiClient = await this.$api.createApiClient()
      apiClient
        .get('appusers')
        .then((r) => { this.data = r.data })
        .catch((e) => this.handleFailedRequest(e))
        .then(() => { this.isLoading = false })
    },
    deleteUser: async function (id) {
      if (!confirm('Are you sure you want to delete this user?')) {
        return
      }
      console.debug('deleting', id)
      this.isLoading = true
      this.error = ''
      this.validationErrors = []

      var apiClient = await this.$api.createApiClient()
      apiClient
        .delete('appusers/' + id)
        .then(() => this.getAllUsers())
        .catch((e) => this.handleFailedRequest(e))
        .then(() => { this.isLoading = false })
    },
    createNewUser: async function () {
      if (!this.newUser) {
        return
      }

      this.isLoading = true
      this.error = ''
      this.validationErrors = []
      var data = JSON.stringify(this.newUser)

      var apiClient = await this.$api.createApiClient()
      apiClient
        .post('appusers', data)
        .then(() => {
          this.newUser = Object.assign({}, vmNewUserDefinition) // clear form
          this.getAllUsers()
        })
        .catch((e) => this.handleFailedRequest(e))
        .then(() => { this.isLoading = false })
    },
    handleFailedRequest: function (e) {
      const data = this.$api.handleFailedRequest(e, true)
      this.validationErrors = data.validationErrors
      this.error = data.error
    }
  }
})
</script>

<template>

  <div class="row heading">
    <h4>Users</h4>
    <p class="grey-text">Control who has access to your school login</p>
  </div>

  <validation-error-display :validationErrors="validationErrors"></validation-error-display>
  <exception-display :error="error"></exception-display>
  <loading-display :isLoading="isLoading"></loading-display>

  <div v-if="!isLoading">

    <!-- user list -->
    <table class="striped responsive-table">
      <thead>
        <tr>
          <th>First Name</th>
          <th>Last Name</th>
          <th>Email</th>
          <th>Created</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="u in data.users" v-bind:key="u.id">
            <td>
                <p class="margin-medium">{{ u.firstName || "-" }}</p>
            </td>
            <td>
                <p class="margin-medium">{{ u.lastName || "-" }}</p>
            </td>
            <td>
                <p class="margin-medium">{{ u.email || "-" }}</p>
            </td>
            <td>
                <p class="margin-medium">{{ u.createdDateFormatted }}</p>
            </td>
            <td class="valign-wrapper section button-strip">
              <a class="btn red" @click="deleteUser(u.id)" :class="{ 'disabled': u.isYou }">Delete</a>
            </td>
        </tr>
      </tbody>
    </table>

    <!-- create form -->
    <div class="card-panel white">
      <div v-if="remainingUserSlots < 1">
        <p>Sorry, you cannot create any more users</p>
      </div>
      <div class="card-content" v-else>
        <span class="card-title">
          <strong>Create new user</strong><br/>
        </span>
        <div class="row">
          <div class="input-field col s12 l3">
            <input id="first_name" type="text" v-model="newUser.firstName">
            <label for="first_name">First Name</label>
          </div>
          <div class="input-field col s12 l3">
            <input id="last_name" type="text" v-model="newUser.lastName">
            <label for="last_name">Last Name</label>
          </div>
          <div class="input-field col s12 l3">
            <input id="email" type="email" class="validate" v-model="newUser.email">
            <label for="email">Email</label>
            <span class="helper-text" data-error="Invalid email" data-success="Email valid">This must be a valid email address</span>
          </div>
        </div>
      </div>
      <div class="card-action" v-if="remainingUserSlots > 0">
        <a class="btn blue" @click="createNewUser()">Create</a>
        <span class="margin-large grey-text">You are allowed {{ remainingUserSlots }} more users</span>
      </div>
    </div>

  </div>

</template>
