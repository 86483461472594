
<script>
import { defineComponent } from 'vue'
import ExceptionDisplay from '@/components/ExceptionDisplay.vue'
import ValidationErrorDisplay from '@/components/ValidationErrorDisplay.vue'
import LoadingDisplay from '@/components/LoadingDisplay.vue'

export default defineComponent({
  components: { ExceptionDisplay, ValidationErrorDisplay, LoadingDisplay },
  name: 'ReportsTopStudentsPage',
  data () {
    return {
      isLoading: false,
      form: {
        availableMonths: [],
        selectedMonth: -1
      },
      results: [],
      dateRangeLabel: '',
      error: '',
      validationErrors: [],
      apiClient: {}
    }
  },

  async mounted () {
    this.apiClient = await this.$api.createApiClient()
    this.loadReport()
  },

  methods: {
    loadReport: function () {
      this.isLoading = true
      this.form = {}
      this.error = ''
      this.validationErrors = []

      this.apiClient
        .get('reports/topStudents')
        .then((r) => {
          this.form.availableMonths = r.data.availableMonths
          this.form.selectedMonth = -1
        })
        .catch((e) => {
          const status = e.status || (e.response ? e.response.status : 0)
          if (status === 401) {
            this.$api.handleLoginExpired()
          } else if (status === 400) {
            this.handleBadRequest(e.response)
          } else {
            this.handleError(e)
          }
        })
        .then(() => {
          this.isLoading = false
        })
    },
    runReport: function () {
      const data = JSON.stringify({ RelativeMonth: this.form.selectedMonth })
      this.isLoading = true
      this.error = ''
      this.validationErrors = []
      this.results = []
      this.dateRangeLabel = ''
      this.apiClient
        .post('reports/topStudents', data)
        .then((r) => {
          this.results = r.data.topStudents
          this.dateRangeLabel = r.data.dateRangeLabel
        })
        .catch((e) => {
          const status = e.status || (e.response ? e.response.status : 0)
          if (status === 401) {
            this.$api.handleLoginExpired()
          } else if (status === 400) {
            this.handleBadRequest(e.response)
          } else {
            this.handleError(e)
          }
        })
        .then(() => {
          this.isLoading = false
        })
    },
    handleBadRequest: function (e) {
      this.validationErrors = e.data.errors
    },
    handleError: function (e) {
      this.error = e.message
    }
  }
})
</script>

<template>

  <div class="row heading">
    <h4>Top Students Report</h4>
    <p class="grey-text">Identify your schools most engaged students</p>
  </div>

  <validation-error-display :validationErrors="validationErrors"></validation-error-display>
  <exception-display :error="error"></exception-display>
  <loading-display :isLoading="isLoading"></loading-display>

  <div v-show="!isLoading">

    <div class="row">
      <div class="col s12 l6">
        <div>
          <strong>Month</strong>
          <select v-model="form.selectedMonth" class="browser-default">
            <option v-for="f in form.availableMonths" :key="f.relativeMonth" :value="f.relativeMonth">{{ f.monthYearLabel }}</option>
          </select>
          </div>
      </div>
      <div class="col s12 l6">
          <a @click="runReport()" class="btn waves-effect waves-blue blue right">Search</a>
      </div>
    </div>

    <!-- chart -->
    <div class="row">
      <div class="col s12 center-align">

          <div class="section">
              <h5>Top 10 Students</h5>
              <div style="color: #999;">{{ dateRangeLabel }}</div>
          </div>

          <div v-if="!results">No results found</div>

          <table v-if="results" class="striped">
              <tbody>
                <tr v-for="s in results" :key="s.studentId">
                    <td class="grey-text">
                        {{ s.positionLabel }}
                    </td>
                    <td class="valign-wrapper">
                      <i v-if="s.position === 1" class="material-icons amber-text">star</i>
                      <i v-if="s.position === 2" class="material-icons grey-text">star</i>
                      <i v-if="s.position === 3" class="material-icons brown-text text-lighten-1">star</i>
                      &nbsp;
                      <router-link :to="{ name: 'Student', params: { id: s.studentId }}">{{ s.studentFullName }}</router-link>
                    </td>
                    <td><span>{{ s.checkInCount }} classes</span></td>
                </tr>
              </tbody>
          </table>

      </div>
    </div>

  </div>

</template>
