<script>
import { defineComponent } from 'vue'
import ExceptionDisplay from '@/components/ExceptionDisplay.vue'
import LoadingDisplay from '@/components/LoadingDisplay.vue'

export default defineComponent({
  components: { ExceptionDisplay, LoadingDisplay },
  name: 'SchoolPlanPage',
  data () {
    return {
      isLoading: false,
      plan: {},
      invoices: [],
      error: '',
      validationErrors: [],
      apiClient: {}
    }
  },

  async mounted () {
    this.apiClient = await this.$api.createApiClient()
    this.getData()
  },

  methods: {
    getData: function () {
      this.isLoading = true
      this.error = ''
      this.validationErrors = []
      this.apiClient
        .get('schoolPlan')
        .then((r) => {
          this.plan = r.data
        })
        .catch((e) => {
          const status = e.status || (e.response ? e.response.status : 0)
          if (status === 401) {
            this.$api.handleLoginExpired()
          } else {
            this.handleError(e)
          }
        })
        .then(() => {
          this.isLoading = false
        })
    },
    getInvoiceList: function () {
      this.isLoading = true
      this.error = ''
      this.validationErrors = []
      this.apiClient
        .get('schoolPlan/invoices')
        .then((r) => {
          this.invoices = r.data
        })
        .catch((e) => {
          const status = e.status || (e.response ? e.response.status : 0)
          if (status === 401) {
            this.$api.handleLoginExpired()
          } else {
            this.handleError(e)
          }
        })
        .then(() => {
          this.isLoading = false
        })
    },
    handleBadRequest: function (e) {
      console.debug('e', e)
      this.validationErrors = e.data.errors
    },
    handleError: function (e) {
      this.error = e.message
    }
  }
})
</script>

<template>
  <div class="row heading">
    <h4>Your Plan</h4>
  </div>

  <exception-display :error="error"></exception-display>
  <loading-display :isLoading="isLoading"></loading-display>

  <div v-if="!isLoading" class="row">
    <div class="col s3">
      <div class="card-panel">
        <strong>Plan Name</strong>
        <p>{{ plan.planName }}</p>
      </div>
    </div>
    <div class="col s3">
      <div class="card-panel">
        <strong>Student Count Limit</strong>
        <p>{{ plan.studentLimitUsed }} / {{ plan.studentLimitTotal }}</p>
      </div>
    </div>
    <div class="col s6">
      <div class="card-panel">
        <strong>Upgrade!</strong>
          <p>
            If you would like to upgrade, please <a href="https://m.me/ossgym" target="_blank">get in touch</a>.
          </p>
      </div>
    </div>
  </div>

  <div v-if="!isLoading" class="row">
    <div class="col s12">
      <div class="card-panel">

        <div class="section">
          <button @click="getInvoiceList()" class="btn blue">Load invoices</button>
        </div>

        <table>
          <thead>
            <tr>
              <td>File</td>
              <td>Created</td>
              <td>Last Updated</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="invoice in invoices" :key="invoice.downloadUri">
              <td>
                <a v-bind:href="invoice.downloadUri" class="valign-wrapper">
                  <i class="material-icons margin-medium-right">download</i>
                  {{ invoice.filename }}
                </a>
              </td>
              <td>{{ invoice.createdDate }}</td>
              <td>{{ invoice.lastUpdatedDate }}</td>
            </tr>
          </tbody>
        </table>

      </div>
    </div>
  </div>

</template>
