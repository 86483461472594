<script>
import emitter from 'tiny-emitter/instance'

const eventKeyLoginStatusChanged = 'login-status-changed'
const eventKeyLoginExpired = 'login-expired'

export default {
  data () {
    return {
      isLoggedIn: false,
      schoolName: ''
    }
  },
  mounted () {
    this.refreshLoggedInUIElements()
    emitter.on(eventKeyLoginStatusChanged, this.refreshLoggedInUIElements)
    emitter.on(eventKeyLoginExpired, this.handleEventLoginExpired)
  },
  methods: {
    refreshLoggedInUIElements: async function () {
      this.isLoggedIn = await this.$api.getIsLoggedIn()
      this.schoolName = localStorage.getItem('schoolName') || 'Not Logged In'
    },
    handleEventLoginExpired: function () {
      // move user back to login to fix login issue
      if (this.$route.name !== 'Login') {
        this.$router.push('/login')
        console.debug('Redirecting to login page')
      } else {
        console.debug('Already on login page')
      }
    },
    gotoKioskSite: async function () {
      // security measure
      await this.$api.logout()
      window.location = 'https://kiosk.ossgym.com'
    },
    logout: async function () {
      this.$api.logout()
    }
  }
}
</script>

<template>
  <header>
    <nav id="header-nav" class="blue darken-4 white-text">
      <div class="container">
        <div class="nav-wrapper">
          <router-link to="/" class="brand-logo center">
            <img
              src="@/assets/oss-logo-header.png"
              class="responsive-img oss-logo" />
          </router-link>

          <div>
            <a href="#" data-target="slide-out" class="sidenav-trigger" data-test-id="header-panel-open-button">
              <i class="material-icons">menu</i>
            </a>
          </div>

        </div>
      </div>
    </nav>

    <div>
      <ul id="slide-out" class="sidenav sidenav-fixed" data-test-id="header-panel">

        <li class="blue darken-3 school-display white-text center-align">
          <span>{{ schoolName }}</span>
        </li>

        <li v-show="!isLoggedIn">
          <router-link to="/login" class="waves-effect sidenav-close">
            <i class="material-icons">input</i> Log in
          </router-link>
        </li>

        <li v-show="isLoggedIn"><a class="subheader">Students</a></li>

        <li v-show="isLoggedIn">
          <router-link to="/student/new" class="waves-effect sidenav-close">
            <i class="material-icons">person_add</i> Register
          </router-link>
        </li>
        <li v-show="isLoggedIn">
          <router-link to="/students" class="waves-effect sidenav-close" data-test-id="header-link-student-search">
            <i class="material-icons">people</i> Search
          </router-link>
        </li>

        <li v-show="isLoggedIn"><a class="subheader">Reports</a></li>

        <li v-show="isLoggedIn">
          <router-link to="/reports/attendence" class="waves-effect sidenav-close">
            <i class="material-icons">bar_chart</i> Attendance
          </router-link>
        </li>
        <li v-show="isLoggedIn">
          <router-link to="/reports/topStudents" class="waves-effect sidenav-close">
            <i class="material-icons">bar_chart</i> Top Students
          </router-link>
        </li>
        <li v-show="isLoggedIn">
          <router-link to="/reports/classes" class="waves-effect sidenav-close">
            <i class="material-icons">bar_chart</i> Class Performance
          </router-link>
        </li>
        <li v-show="isLoggedIn">
          <router-link to="/reports/progress" class="waves-effect sidenav-close">
            <i class="material-icons">bar_chart</i> School Progress
          </router-link>
        </li>

        <li v-show="isLoggedIn"><a class="subheader">School</a></li>

        <li v-show="isLoggedIn">
          <router-link to="/attention" class="waves-effect sidenav-close">
            <i class="material-icons">warning</i> Attention
          </router-link>
        </li>
        <li v-show="isLoggedIn">
          <router-link to="/plans" class="waves-effect sidenav-close">
            <i class="material-icons">repeat</i> Plans
          </router-link>
        </li>
        <li v-show="isLoggedIn">
          <router-link to="/classes" class="waves-effect sidenav-close" data-test-id="header-link-classes">
            <i class="material-icons">book</i> Classes
          </router-link>
        </li>
        <li v-show="isLoggedIn">
          <router-link to="/settings" class="waves-effect sidenav-close">
            <i class="material-icons">settings</i> Settings
          </router-link>
        </li>
        <li v-show="isLoggedIn">
          <router-link to="/bulkEmail" class="waves-effect sidenav-close">
            <i class="material-icons">email</i> Bulk Email
          </router-link>
        </li>
        <li v-show="isLoggedIn">
          <router-link to="/users" class="waves-effect sidenav-close">
            <i class="material-icons">people</i> Users
          </router-link>
        </li>
        <li v-show="isLoggedIn">
          <router-link to="/school/plan" class="waves-effect sidenav-close">
            <i class="material-icons">grade</i> Your Plan
          </router-link>
        </li>
        <li v-show="isLoggedIn">
          <a class="waves-effect sidenav-close" @click="logout()">
            <i class="material-icons">directions_run</i> Log out
          </a>
        </li>
      </ul>
    </div>
  </header>
</template>
