<script>
import LoadingDisplay from '@/components/LoadingDisplay.vue'
const sleepForMs = m => new Promise(resolve => setTimeout(resolve, m))

export default {
  components: { LoadingDisplay },
  props: ['modelValue', 'imageBaseUrl', 'hideDeleteButton'],
  emits: ['update:modelValue'],
  data () {
    return {
      error: '',
      isLoading: false,
      apiClient: {},
      photoUrl: this.modelValue,
      randomId: (Math.random() + '').substring(2, 6) // ensures the file input element is unique on the broader form
    }
  },
  async created () {
    this.apiClient = await this.$api.createApiClient()
  },
  methods: {
    openUploadPrompt: function () {
      document.getElementById(this.randomId).click()
    },
    uploadImage: async function (event) {
      this.error = ''
      this.photoUrl = ''
      this.isLoading = true

      const data = new FormData()
      data.append('file', event.target.files[0])
      const config = {
        header: {
          'Content-Type': 'image/png'
        }
      }
      // upload
      this.apiClient
        .post('settings/site/upload', data, config)
        .then((r) => {
          this.isLoading = false
          console.debug(JSON.stringify(r))
          this.$emit('update:modelValue', r.data.filename)
        })
        .catch((e) => {
          const status = e.status || (e.response ? e.response.status : 0)
          console.debug('status', status)
          if (status === 401) {
            this.error = 'Your login expired'
          } else if (status === 400) {
            this.error = (e.response.data?.errors[0] || 'Unexpected error')
          } else {
            this.error = 'Unexpected server error'
          }
        })
        .then(() => {
          this.isLoading = false
        })
    },
    deleteImage: function () {
      if (!confirm('Are you sure you want to delete this image?')) {
        return
      }
      this.$emit('update:modelValue', '')
    }
  }
}
</script>

<style scoped>
  .image-preview {
    width: auto;
    max-width: 300px;
    height: 200px;
    background-size: contain;
    background-position: left;
    background-repeat: no-repeat;
  }
</style>

<template>
  <div>
    <div v-if="modelValue" class="image-preview" :style="{'background-image': 'url(\'' + imageBaseUrl + '/' + modelValue + '\')'}">
    </div>
    <loading-display :isLoading="isLoading" />
    <input class="hide" :id="randomId" type="file" accept=".jpeg, .jpg, .gif, .png" @change="uploadImage($event)" />
    <a class="btn blue" @click="openUploadPrompt()">Upload</a>
    <a v-if="modelValue && !hideDeleteButton" class="btn red" @click="deleteImage()">Delete</a>
    <span v-if="error" class="red-text">{{ error }}</span>
  </div>
</template>
