<script>
import { defineComponent } from 'vue'
import StudentEditForm from '@/components/StudentEditForm.vue'
import ExceptionDisplay from '@/components/ExceptionDisplay.vue'
import ValidationErrorDisplay from '@/components/ValidationErrorDisplay.vue'
import LoadingDisplay from '@/components/LoadingDisplay.vue'

export default defineComponent({
  name: 'StudentRegisterPage',
  components: {
    StudentEditForm,
    ExceptionDisplay,
    ValidationErrorDisplay,
    LoadingDisplay
  },
  data () {
    return {
      error: '',
      validationErrors: [],
      isLoading: false,
      student: {
        isEmailsEnabled: true
      },
      meta: {},
      apiClient: {}
    }
  },

  async mounted () {
    this.apiClient = await this.$api.createApiClient()
    this.getMeta()
  },

  methods: {
    getMeta: function () {
      this.isLoading = true
      this.error = ''
      this.validationErrors = []
      this.meta = {}
      this.apiClient
        .get('meta/students')
        .then((r) => {
          this.meta = r.data
          console.debug('meta', this.meta)
        })
        .catch((e) => {
          const status = e.status || (e.response ? e.response.status : 0)
          console.debug('status', status)
          if (status === 401) {
            this.$api.handleLoginExpired()
          } else if (status === 400) {
            this.handleBadRequest(e.response)
          } else {
            this.handleError(e)
          }
        })
        .then(() => {
          this.isLoading = false
        })
    },
    save: function () {
      this.isLoading = true
      this.error = ''
      this.validationErrors = []
      const data = JSON.stringify(this.student)
      this.apiClient
        .post('students', data)
        .then((d) => {
          console.debug('success', JSON.stringify(d))
          // refresh
          this.$router.push('/student/' + d.data.studentId)
        })
        .catch((e) => {
          const status = e.status || (e.response ? e.response.status : 0)
          if (status === 401) {
            this.$api.handleLoginExpired()
          } else if (status === 400) {
            this.handleBadRequest(e.response)
          } else {
            this.handleError(e)
          }
        })
        .then(() => {
          this.isLoading = false
        })
    },
    goBack: function () {
      this.$router.go(-1)
    },
    handleBadRequest: function (e) {
      if (e.data.Message) {
        this.validationErrors = [e.data.Message]
      } else {
        this.validationErrors = e.data.errors
      }
    },
    handleError: function (e) {
      const errString = JSON.stringify(e)
      console.error('api error', errString)
      this.error = e.message
    }
  }
})
</script>

<template>
<div class="row heading">
    <h4>Student Registration</h4>
    <p class="grey-text" v-if="meta.canCreateMoreStudents">You can add {{ meta.studentSlotsRemaining }} more students on your current plan. Get more by <router-link to="/upgrade">upgrading!</router-link></p>
</div>

<validation-error-display :validationErrors="validationErrors"></validation-error-display>
<exception-display :error="error"></exception-display>
<loading-display :isLoading="isLoading"></loading-display>

<div v-if="!isLoading && !meta.canCreateMoreStudents" class="row">
  <p>Sorry, you can not create anymore students on your current plan. Please <router-link to="/upgrade">upgrade your plan</router-link> or remove existing students.</p>
</div>

<div v-if="!isLoading && meta.canCreateMoreStudents">

  <student-edit-form :studentRef="student" />

  <div class="row">
    <p><strong>NOTE:</strong> Photos can be uploaded once student is registered</p>
  </div>

  <div class="row card">
    <div class="card-content">
      <p><strong>NOTE:</strong> Based on your settings, this student will automatically start a <strong>{{ meta.trialLengthInDays }}</strong> day trial. Once their trial has ended they won't be able to sign in, and will need to be signed up for a plan.</p>
    </div>
  </div>

  <div class="row button-strip">
    <a class="btn blue" @click="save()">Register</a>
    <a class="btn grey" @click="goBack()">Back</a>
  </div>

</div>
</template>
