<script>
export default {
  props: {
    studentRef: Object
  },
  data () {
    return {
      student: this.studentRef
    }
  }
}
</script>

<template>

  <h5 class="grey-text">Required fields</h5>

  <fieldset class="row yellow lighten-4">
    <div class="section">

      <div class="row">
        <div class="col s12 l6">
          <strong>First Name</strong>
          <input type="text" v-model="student.firstName" />
        </div>
        <div class="col s12 l6">
          <strong>Last Name</strong>
          <input type="text" v-model="student.lastName" />
        </div>
      </div>

      <div class="row">
        <div class="col s12 l6">
          <strong>Email</strong>
          <input type="email" v-model="student.email" />
        </div>
        <div class="col s12 l6">
          <strong>Phone</strong>
          <input type="tel" v-model="student.phone" />
        </div>
      </div>

    </div>
  </fieldset>

  <h5 class="grey-text">Optional fields</h5>

  <fieldset class="row grey lighten-4">
    <div class="section">

      <div class="row">
        <div class="col s12 l6">
          <strong>Dob</strong>
          <input type="date" v-model="student.dob" />
        </div>
      </div>

      <div class="row">
        <div class="col s12 l6">
          <strong>Current Rank</strong>
          <input type="text" v-model="student.currentRank" />
        </div>
        <div class="col s12 l6">
          <strong>Rank Obtained</strong>
          <input type="date" v-model="student.rankObtained" />
        </div>
      </div>

      <div class="row">
        <div class="col s12 l6">
          <strong>Emergency Contact Name</strong>
          <input type="text" v-model="student.emergencyContactName" />
        </div>
        <div class="col s12 l6">
          <strong>Emergency Contact Phone</strong>
          <input type="tel" v-model="student.emergencyContactPhone" />
        </div>
      </div>

      <div class="row">
        <div class="col s12 l6">
          <strong>Address 1</strong>
          <input type="text" v-model="student.address1" />
        </div>
        <div class="col s12 l6">
          <strong>Address 2</strong>
          <input type="text" v-model="student.address2" />
        </div>
      </div>

      <div class="row">
        <div class="col s12 l6">
          <strong>Suburb</strong>
          <input type="text" v-model="student.suburb" />
        </div>
        <div class="col s12 l6">
          <strong>State</strong>
          <input type="text" v-model="student.state" />
        </div>
      </div>

      <div class="row">
        <div class="col s12 l6">
          <strong>Post Code</strong>
          <input type="text" v-model="student.postCode" />
        </div>
      </div>

    </div>
  </fieldset>

  <h5 class="grey-text">Student Settings</h5>

  <div class="row">
    <div class="col s12">
      <div class="section">
        <label>
          <input class="filled-in" type="checkbox" v-model="student.isInstructor" />
          <span>Is Instructor</span>
        </label>
      </div>
      <div class="section">
        <label>
          <input class="filled-in" type="checkbox" v-model="student.isDisabled" />
          <span>Is Disabled</span>
        </label>
      </div>
    </div>
  </div>

  <h5 class="grey-text">Email settings</h5>

  <div class="row">
    <div class="col s12">
      <div class="section">
        <label>
          <input class="filled-in" type="checkbox" v-model="student.isEmailsEnabled" />
          <span>Student wants to recieve bulk emails sent by this school</span>
        </label>
      </div>
      <div>
        <p>Email groups</p>
        <div class="section">
          <label>
            <input class="filled-in" type="checkbox" v-model="student.isTaggedAsAdult" />
            <span>Adults</span>
          </label>
        </div>
        <div class="section">
          <label>
            <input class="filled-in" type="checkbox" v-model="student.isTaggedAsKid" />
            <span>Parents/Kids</span>
          </label>
        </div>
      </div>
    </div>
  </div>

</template>
