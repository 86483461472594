<script>
import { defineComponent } from 'vue'
import ExceptionDisplay from '@/components/ExceptionDisplay.vue'
import ValidationErrorDisplay from '@/components/ValidationErrorDisplay.vue'
import LoadingDisplay from '@/components/LoadingDisplay.vue'

export default defineComponent({
  components: { ExceptionDisplay, ValidationErrorDisplay, LoadingDisplay },
  name: 'BulkEmailPage',
  data () {
    return {
      isEmailSent: false,
      tagFilters: [{ label: 'Adults', tag: 'Adult', selected: true }, { label: 'Kids/Parents', tag: 'Kid', selected: true }],
      title: '',
      body: '',
      error: '',
      validationErrors: [],
      isLoading: false,
      apiClient: {}
    }
  },

  async created () {
    this.apiClient = await this.$api.createApiClient()
  },

  mounted () {
    document.runMaterializeInit()
  },

  methods: {
    sendEmail: function () {
      this.error = ''
      this.isEmailSent = false
      this.validationErrors = []
      this.isLoading = true

      const tags = this.tagFilters.filter((f) => f.selected).map((f) => f.tag)

      // Prep payload
      const data = JSON.stringify({
        title: this.title,
        body: this.body,
        tags: tags
      })

      // Send
      this.apiClient.post('bulkEmail', data)
        .then(() => {
          this.isEmailSent = true
          this.title = ''
          this.body = ''
        })
        .catch((e) => {
          const status = e.status || (e.response ? e.response.status : 0)
          if (status === 401) {
            this.$api.handleLoginExpired()
          } else if (status === 400) {
            this.handleBadRequest(e.response)
          } else {
            this.handleError(e)
          }
        })
        .then(() => {
          this.isLoading = false
        })
    },
    handleBadRequest: function (e) {
      if (e.data.Message) {
        this.validationErrors = [e.data.Message]
      } else {
        this.validationErrors = e.data.errors
      }
    },
    handleError: function (e) {
      // const errString = JSON.stringify(e)
      // console.debug('api error', errString)
      this.error = e.message
    }
  }

})
</script>

<template>
  <div class="row heading">
    <h4>Bulk Email</h4>
    <p class="grey-text">This will send an email to all students who have opted-in for bulk emails</p>
  </div>

  <exception-display :error="error"></exception-display>
  <validation-error-display :validationErrors="validationErrors"></validation-error-display>
  <loading-display :isLoading="isLoading"></loading-display>

  <div v-show="!isLoading" class="row">

    <div class="col s12">
      <div class="card-panel">

        <div class="center-align">
          <div style="display: inline-block;">
            <div v-if="isEmailSent" class="valign-wrapper">
              <i class="material-icons small green-text margin-medium-right">check_circle</i> Email was sent
            </div>
          </div>
        </div>

        <div class="input-field">
          <strong>Email groups to send to</strong>
          <div class="section" v-for="filter in tagFilters" :key="filter.tag">
            <label>
              <input class="filled-in" type="checkbox" v-model="filter.selected" />
              <span>{{ filter.label }}</span>
            </label>
          </div>
        </div>

        <div class="input-field">
          <strong>Title</strong>
          <input v-model="title" name="title" id="title" class="counted" type="text" data-length="70" />
        </div>
        <div class="input-field">
          <strong>Body</strong>
          <textarea v-model="body" class="materialize-textarea counted"></textarea>
        </div>
      </div>
    </div>

    <div class="settings-section center-align">
      <button class="btn waves-effect waves-blue blue" @click="sendEmail()">Send</button>
    </div>

  </div>

</template>
