<script>
export default {
  props: {
    student: Object
  }
}
</script>

<template>
  <table class="striped">
      <tbody>
          <tr v-if="student.model.email">
              <td><label>Email</label></td>
              <td>{{ student.model.email }}</td>
          </tr>
          <tr v-if="student.model.phone">
              <td><label>Phone</label></td>
              <td>
                <div>
                  <a :href="'tel:' + student.model.phone">{{ student.model.phone }}</a>
                </div>
              </td>
          </tr>
          <tr v-if="student.model.dobFormatted">
              <td><label>Dob</label></td>
              <td>
                {{ student.model.dobFormatted }}
                <div v-if="student.model.age">({{ student.model.age }} years old)</div>
              </td>
          </tr>
          <tr v-if="student.model.currentRank">
              <td><label>Current Rank</label></td>
              <td>{{ student.model.currentRank }}</td>
          </tr>
          <tr v-if="student.model.rankObtainedFormatted">
              <td><label>Rank Obtained</label></td>
              <td>
                  {{ student.model.rankObtainedFormatted }}
                  <div v-if="student.timeAtRank">({{ student.timeAtRank }} ago)</div>
              </td>
          </tr>
          <tr>
              <td><label>Classes Attended</label></td>
              <td>{{ student.classesAttended }}</td>
          </tr>
          <tr>
              <td><label>Last Check In</label></td>
              <td>{{ student.lastCheckIn || 'never' }}</td>
          </tr>
          <tr>
              <td><label>Created At</label></td>
              <td>{{ student.createdAt }}</td>
          </tr>
          <tr v-if="student.model.emergencyContactName">
              <td><label>Emergency Contact Name</label></td>
              <td>{{ student.model.emergencyContactName }}</td>
          </tr>
          <tr v-if="student.model.emergencyContactPhone">
              <td><label>Emergency Contact Phone</label></td>
              <td>
                <div>
                  <a :href="'tel:' + student.model.emergencyContactPhone">{{ student.model.emergencyContactPhone }}</a>
                </div>
              </td>
          </tr>
          <tr v-if="student.model.address1">
              <td><label>Address</label></td>
              <td>
                <p>{{ student.model.address1 }}</p>
                <p>{{ student.model.address2 }}</p>
              </td>
          </tr>
          <tr v-if="student.model.postCode">
              <td><label>Post Code</label></td>
              <td>
                <p>{{ student.model.postCode }}</p>
              </td>
          </tr>
          <tr v-if="student.model.suburb">
              <td><label>Suburb</label></td>
              <td>
                <p>{{ student.model.suburb }}</p>
              </td>
          </tr>
          <tr v-if="student.model.state">
              <td><label>State</label></td>
              <td>
                <p>{{ student.model.state }}</p>
              </td>
          </tr>
          <tr>
              <td><label>Is Emails Enabled</label></td>
              <td>
                <span v-if="student.model.isEmailsEnabled"><i class="material-icons green-text">done</i></span>
                <span v-if="!student.model.isEmailsEnabled"><i class="material-icons red-text">close</i></span>
              </td>
          </tr>
          <tr>
              <td><label>Is Instructor</label></td>
              <td>
                <span v-if="student.model.isInstructor"><i class="material-icons green-text">done</i></span>
                <span v-if="!student.model.isInstructor"><i class="material-icons red-text">close</i></span>
              </td>
          </tr>
          <tr>
              <td><label>Is Disabled</label></td>
              <td>
                <span v-if="student.model.isDisabled"><i class="material-icons green-text">done</i></span>
                <span v-if="!student.model.isDisabled"><i class="material-icons red-text">close</i></span>
              </td>
          </tr>
          <tr>
              <td><label>Plan</label></td>
              <td>
                  {{ student.planName }}
              </td>
          </tr>
          <tr>
              <td><label>Plan Status</label></td>
              <td>
                  {{ student.planStatusLabel }}
              </td>
          </tr>
      </tbody>
  </table>
</template>
