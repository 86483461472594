<script>
import { defineComponent } from 'vue'
import ExceptionDisplay from '@/components/ExceptionDisplay.vue'
import ValidationErrorDisplay from '@/components/ValidationErrorDisplay.vue'
import LoadingDisplay from '@/components/LoadingDisplay.vue'

export default defineComponent({
  components: { ExceptionDisplay, ValidationErrorDisplay, LoadingDisplay },
  name: 'StudentPlanApplyPage',
  data () {
    return {
      error: '',
      validationErrors: [],
      isLoading: false,
      studentId: '',
      planId: '',
      form: {},
      apiClient: {},
      ezidebitId: '2'
    }
  },

  async mounted () {
    this.apiClient = await this.$api.createApiClient()
    this.studentId = this.$route.params.id
    console.debug('studentId', this.studentId)
    this.planId = this.$route.params.planId
    console.debug('planId', this.planId)
    this.getForm()
  },

  methods: {
    getForm: function () {
      this.isLoading = true
      this.error = ''
      this.validationErrors = []
      this.apiClient
        .get('students/' + this.studentId + '/plan/' + this.planId + '/assign')
        .then((r) => {
          console.debug('template', r.data)
          this.form = r.data
        })
        .catch((e) => {
          const status = e.status || (e.response ? e.response.status : 0)
          if (status === 401) {
            this.$api.handleLoginExpired()
          } else if (status === 400) {
            this.handleBadRequest(e.response)
          } else {
            this.handleError(e)
          }
        })
        .then(() => {
          this.isLoading = false
        })
    },
    activate: function () {
      this.isLoading = true
      this.error = ''
      this.validationErrors = []
      const data = JSON.stringify(this.form)
      let shouldRunFinally = true
      this.apiClient
        .post('students/' + this.studentId + '/plan/' + this.planId + '/assign', data)
        .then((r) => {
          console.debug('resp', r.data)
          shouldRunFinally = false
          this.isLoading = true
          if (r.data.redirectUrl) {
            window.location = r.data.redirectUrl
          } else {
            this.$router.push('/student/' + this.studentId + '/plan')
          }
        })
        .catch((e) => {
          const status = e.status || (e.response ? e.response.status : 0)
          if (status === 401) {
            this.$api.handleLoginExpired()
          } else if (status === 400) {
            this.handleBadRequest(e.response)
          } else {
            this.handleError(e)
          }
        })
        .then(() => {
          if (shouldRunFinally) {
            this.isLoading = false
          }
        })
    },
    goBack: function () {
      this.$router.push('/student/' + this.studentId)
    },
    handleBadRequest: function (e) {
      if (e.data.Message) {
        this.validationErrors = [e.data.Message]
      } else {
        this.validationErrors = e.data.errors
      }
    },
    handleError: function (e) {
      const errString = JSON.stringify(e)
      console.error('api error', errString)
      this.error = e.message
    }
  }
})
</script>

<template>

  <div class="row heading" data-test-id="student-plan-apply-page">
      <h4>Activate Plan</h4>
  </div>

  <validation-error-display :validationErrors="validationErrors"></validation-error-display>
  <exception-display :error="error"></exception-display>
  <loading-display :isLoading="isLoading"></loading-display>

  <div v-show="!isLoading">
    <div class="row">
      <div class="col s12 l9">
        <table class="striped">

          <tr>
              <td>Student</td>
              <td>{{ form.studentFullName }}</td>
          </tr>

          <tr>
              <td>Plan</td>
              <td>{{ form.planName }}</td>
          </tr>

          <tr>
              <td>Payment Method</td>
              <td>
                  <div class="input-field">
                    <select @change="form.selectedMethodId = $event.target.value" class="browser-default" data-test-id="payment-method-dropdown">
                      <option value="" selected="selected">Select an option ...</option>
                      <option v-for="m in form.methodOptions" :key="m.id" :value="m.id">{{ m.name }}</option>
                    </select>
                  </div>
                  <img v-show="form.selectedMethodId === ezidebitId" height="20" class="margin-medium-top-bottom" src="@/assets/ezidebit-logo.svg" />
              </td>
          </tr>

          <tr v-show="form.selectedMethodId !== ezidebitId">
            <td>Price</td>
            <td>
              <div class="input-field">
                  <i class="material-icons prefix small">attach_money</i>
                  <input v-model.number="form.price" type="number" data-test-id="plan-price-input" />
              </div>
            </td>
          </tr>

          <tr v-show="form.selectedMethodId !== ezidebitId">
            <td>Frequency</td>
            <td>
              <div class="input-field">
                <select @change="form.selectedFrequencyId = $event.target.value" class="browser-default" data-test-id="plan-frequency-dropdown">
                  <option value="" selected="selected">Select an option ...</option>
                  <option v-for="f in form.frequencyOptions" :key="f.id" :value="f.id">{{ f.name }}</option>
                </select>
              </div>
            </td>
          </tr>

          <tr v-show="form.selectedMethodId !== ezidebitId">
            <td>Number of payments</td>
            <td>
              <div class="input-field">
                <p>
                  <label>
                    <input v-model="form.isOngoing" type="checkbox" class="filled-in is-ongoing-toggle" />
                    <span data-test-id="plan-is-ongoing-toggle">Ongoing</span>
                  </label>
                </p>
              </div>
              <div v-show="!form.isOngoing" class="input-field">
                <p>
                  <input v-model.number="form.totalPayments" type="number" data-test-id="plan-total-payments-input" />
                </p>
              </div>
            </td>
          </tr>

          <tr v-show="form.selectedMethodId !== ezidebitId">
            <td>Setup Fee</td>
            <td>
              <div class="input-field">
                <i class="material-icons prefix">attach_money</i>
                <input v-model.number="form.setupFee" type="number" data-test-id="plan-setup-fee-input" />
              </div>
            </td>
          </tr>

          <tr v-show="form.selectedMethodId !== ezidebitId && form.lastContractEnded">
            <td>Last Contract Ended</td>
            <td>
              <div class="input-field">
                {{ form.lastContractEndedFormatted }}
              </div>
            </td>
          </tr>

          <tr v-show="form.selectedMethodId !== ezidebitId">
            <td>Contract Start</td>
            <td>
                <p><strong>Hint:</strong> This is the date of first payment</p>
                <p>If a weekend day is selected, it will change to Monday</p>
                <div class="input-field">
                    <input v-model="form.contractStartDate" type="date" data-test-id="plan-start-date-input" />
                </div>
            </td>
          </tr>

        </table>

        <!-- ezidebit notes -->
        <div v-show="form.selectedMethodId == ezidebitId" class="section card-panel">
          <strong>Ezidebit notes</strong>
          <p>Once an Ezidebit plan has been created for this student:</p>
          <ul class="browser-default">
            <li>Dishonored payments will be tried again in 3 business days time.</li>
            <li>Any changes to contact details in Oss will be pushed to Ezidebit, overwriting existing information for this student.</li>
            <li>Any setup fees should be captured separately to this process.</li>
          </ul>
        </div>

        <div class="section">
          <a @click="activate()" class="btn blue" data-test-id="plan-activate-button">Activate</a>
        </div>

      </div>
    </div>

  </div>
</template>
