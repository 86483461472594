
<script>
import { defineComponent } from 'vue'
import Chart from 'chart.js'
import ExceptionDisplay from '@/components/ExceptionDisplay.vue'
import ValidationErrorDisplay from '@/components/ValidationErrorDisplay.vue'
import LoadingDisplay from '@/components/LoadingDisplay.vue'

export default defineComponent({
  components: { ExceptionDisplay, ValidationErrorDisplay, LoadingDisplay },
  name: 'ReportsAttendencePage',
  data () {
    return {
      isLoading: false,
      hasSearchRun: false,
      form: {
        fromDate: '',
        fromTime: '',
        toDate: '',
        toTime: '',
        selectedClassId: '',
        selectedStudentId: '',
        classFilters: [],
        studentFilters: []
      },
      includeRawData: false,
      chartData: {
        datasets: [],
        labels: []
      },
      rawSignIns: [],
      error: '',
      validationErrors: [],
      apiClient: {}
    }
  },

  async mounted () {
    this.apiClient = await this.$api.createApiClient()
    this.loadReport(this.$route.params.studentId)
  },

  computed: {
    hasChartData: function () {
      var hasData = false

      if (this.chartData && this.chartData.datasets) {
        hasData = (this.chartData.datasets.length > 0)
      }

      return hasData
    },
    hasRawData: function () {
      var hasData = false

      if (this.rawSignIns) {
        hasData = (this.rawSignIns.length > 0)
      }

      return hasData
    }
  },

  methods: {
    loadReport: function (studentId) {
      this.isLoading = true
      this.error = ''
      this.validationErrors = []
      const studentIdPreload = studentId
      this.apiClient
        .get('reports/attendence')
        .then((r) => {
          this.form = r.data
          this.form.selectedClassId = ''
          this.form.selectedStudentId = ''
          if (studentIdPreload) {
            this.form.selectedStudentId = studentIdPreload
          }
        })
        .catch((e) => {
          const status = e.status || (e.response ? e.response.status : 0)
          if (status === 401) {
            this.$api.handleLoginExpired()
          } else if (status === 400) {
            this.handleBadRequest(e.response)
          } else {
            this.handleError(e)
          }
        })
        .then(() => {
          this.isLoading = false
        })
    },
    runReport: function () {
      const data = JSON.stringify(this.form)
      this.isLoading = true
      this.error = ''
      this.validationErrors = []
      this.chartData = {}
      this.rawSignIns = []

      var uri = this.includeRawData ? 'reports/attendence?includeRawData' : 'reports/attendence'

      this.apiClient
        .post(uri, data)
        .then((r) => {
          this.hasSearchRun = true
          this.chartData = r.data.chartData
          this.rawSignIns = r.data.rawSignIns
          this.displayChart()
        })
        .catch((e) => {
          const status = e.status || (e.response ? e.response.status : 0)
          if (status === 401) {
            this.$api.handleLoginExpired()
          } else if (status === 400) {
            this.handleBadRequest(e.response)
          } else {
            this.handleError(e)
          }
        })
        .then(() => {
          this.isLoading = false
        })
    },
    deleteSignIn: function (signInId, studentId) {
      if (!confirm('Are you sure you want to delete this sign-in?')) {
        return
      }

      this.isLoading = true

      this.apiClient
        .delete('students/' + studentId + '/manualSignin/' + signInId)
        .then(() => {
          this.rawSignIns = this.rawSignIns.filter(x => x.id !== signInId) // remove sign in from list
          document.popToast('Deleted sign-in successfully')
        })
        .catch((e) => {
          const status = e.status || (e.response ? e.response.status : 0)
          if (status === 401) {
            this.$api.handleLoginExpired()
          } else if (status === 400) {
            this.handleBadRequest(e.response)
          } else {
            this.handleError(e)
          }
        })
        .then(() => {
          this.isLoading = false
        })
    },
    handleBadRequest: function (e) {
      this.validationErrors = e.data.errors
    },
    handleError: function (e) {
      this.error = e.message
    },
    displayChart: function () {
      try {
        const ctx = document.getElementById('myChart')
        var myBarChart = new Chart(ctx, {
          type: 'line',
          data: this.chartData,
          options: {
            scales: {
              yAxes: [{
                ticks: {
                  min: 0
                }
              }]
            }
          }
        })
      } catch (e) {
        console.error(e)
      }
    }
  }
})
</script>

<template>

  <div class="row heading">
    <h4>Attendence Report</h4>
    <p class="grey-text">Track student attendence over time</p>
  </div>

  <validation-error-display :validationErrors="validationErrors"></validation-error-display>
  <exception-display :error="error"></exception-display>
  <loading-display :isLoading="isLoading"></loading-display>

  <div v-show="!isLoading">

    <div class="row">
      <div class="col s12 l6">
          <div class="input-field">
              <strong>From date</strong>
              <input v-model="form.fromDate" type="date" />
          </div>
          <div class="input-field">
              <strong>From time</strong>
              <input v-model="form.fromTime" type="time" />
          </div>
      </div>

      <div class="col s12 l6">
          <div class="input-field">
              <strong>To date</strong>
              <input v-model="form.toDate" type="date" />
          </div>
          <div class="input-field">
              <strong>To time</strong>
              <input v-model="form.toTime" type="time" />
          </div>
      </div>
    </div>

    <div class="row">
      <div class="col s12 l6">
        <div>
              <strong>Class filter</strong>
              <select @change="form.selectedClassId = $event.target.value" class="browser-default">
                <option value="" selected="selected">All Classes</option>
                <option v-for="f in form.classFilters" :key="f.id" :value="f.id">{{ f.name }}</option>
              </select>
          </div>
      </div>
      <div class="col s12 l6">
          <div>
              <strong>Student filter</strong>
              <select v-model="form.selectedStudentId" class="browser-default">
                <option value="" selected="selected">All Students</option>
                <option v-for="f in form.studentFilters" :key="f.id" :value="f.id">{{ f.name }}</option>
              </select>
          </div>
      </div>
    </div>

    <div class="row">
      <div class="col s12 l6">
      </div>
      <div class="col s12 l6">
          <p>
            <label>
              <input v-model="includeRawData" type="checkbox" class="filled-in" />
              <span>Include raw sign-in data below chart</span>
            </label>
          </p>
          <a @click="runReport()" class="btn waves-effect waves-blue blue right">Search</a>
      </div>
    </div>

    <!-- chart -->
    <div v-show="hasSearchRun" class="row">
      <div class="col s12">

        <h5>Chart</h5>

        <div v-show="!hasChartData">No results found</div>

        <div v-show="hasChartData" id="chartContainer" class="chart-container" style="position: relative;">
            <canvas id="myChart"></canvas>
        </div>

      </div>
    </div>

    <!-- raw sign ins -->
    <div v-if="includeRawData && hasSearchRun" class="row">
      <div class="col s12">

        <h5>Sign-ins</h5>

        <div v-show="!hasRawData">No results found</div>

        <table v-if="hasRawData" class="striped">
          <tbody>
            <tr v-for="s in rawSignIns" :key="s.studentId">
              <td>
                <router-link :to="{ name: 'Student', params: { id: s.studentId }}">
                  {{ s.studentFullName }}
                </router-link>
                <span v-if="s.isDisabled" class="grey-text"> (disabled)</span>
              </td>
              <td>
                {{ s.classNames }}
              </td>
              <td>
                <span>{{ s.timestampFormatted }}</span>
              </td>
              <td>
                <a href="#" v-on:click="deleteSignIn(s.id, s.studentId)" title="Delete this sign-in"><i class="material-icons">delete</i></a>
              </td>
            </tr>
          </tbody>
        </table>

      </div>
    </div>

  </div>

</template>
