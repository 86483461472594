import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { trackRouter } from 'vue-gtag-next'
import DashboardPage from '../views/DashboardPage.vue'
import StudentsPage from '../views/StudentsPage.vue'
import StudentDetailsPage from '../views/StudentDetailsPage.vue'
import StudentRegisterPage from '../views/StudentRegisterPage.vue'
import LoginPage1 from '../views/LoginPage1.vue'
import LogoutPage from '../views/LogoutPage.vue'
import SettingsPage from '../views/SettingsPage.vue'
import SettingsWebsitePage from '../views/SettingsWebsitePage.vue'
import AttentionPage from '../views/AttentionPage.vue'
import ClassesPage from '../views/ClassesPage.vue'
import PlansPage from '../views/PlansPage.vue'
import BulkEmailPage from '../views/BulkEmailPage.vue'
import ReportsClassesPage from '../views/ReportsClassesPage.vue'
import ReportsTopStudentsPage from '../views/ReportsTopStudentsPage.vue'
import ReportsAttendencePage from '../views/ReportsAttendencePage.vue'
import ReportsProgressPage from '../views/ReportsProgressPage.vue'
import StudentPlanPage from '../views/StudentPlanPage.vue'
import StudentPlanApplyPage from '../views/StudentPlanApplyPage.vue'
import StudentDeletePage from '../views/StudentDeletePage.vue'
import UpgradePage from '../views/UpgradePage.vue'
import EziReconcilePage from '../views/EziReconcilePage.vue'
import SchoolPlanPage from '../views/SchoolPlanPage.vue'
import UsersPage from '../views/UsersPage.vue'

const routes:RouteRecordRaw[] = [
  {
    path: '/',
    name: 'Dashboard',
    component: DashboardPage,
    meta: {
      title: 'Dashboard'
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginPage1,
    meta: {
      title: 'Login',
      noAuth: true,
      noMenu: true
    }
  },
  {
    path: '/logout',
    name: 'Logout',
    component: LogoutPage,
    meta: {
      title: 'Logout',
      noAuth: true,
      noMenu: true
    }
  },
  {
    path: '/settings',
    name: 'Settings',
    component: SettingsPage,
    meta: {
      title: 'Settings'
    }
  },
  {
    path: '/settings/website',
    name: 'Website Settings',
    component: SettingsWebsitePage,
    meta: {
      title: 'Website Settings'
    }
  },
  {
    path: '/school/plan',
    name: 'SchoolPlan',
    component: SchoolPlanPage,
    meta: {
      title: 'School Plan'
    }
  },
  {
    path: '/students/:filter?',
    name: 'Students',
    component: StudentsPage,
    meta: {
      title: 'Students'
    }
  },
  {
    path: '/student/:id',
    name: 'Student',
    component: StudentDetailsPage,
    meta: {
      title: 'Student'
    }
  },
  {
    path: '/student/new',
    name: 'StudentRegister',
    component: StudentRegisterPage,
    meta: {
      title: 'Student Register'
    }
  },
  {
    path: '/student/:id/plan',
    name: 'StudentPlan',
    component: StudentPlanPage,
    meta: {
      title: 'Student Plan'
    }
  },
  {
    path: '/student/:id/plan/:planId/apply',
    name: 'StudentPlanApply',
    component: StudentPlanApplyPage,
    meta: {
      title: 'Student Plan Apply'
    }
  },
  {
    path: '/student/:id/delete',
    name: 'StudentDelete',
    component: StudentDeletePage,
    meta: {
      title: 'Student Delete'
    }
  },
  {
    path: '/attention',
    name: 'Attention',
    component: AttentionPage,
    meta: {
      title: 'Attention'
    }
  },
  {
    path: '/classes',
    name: 'Classes',
    component: ClassesPage,
    meta: {
      title: 'Classes'
    }
  },
  {
    path: '/plans',
    name: 'Plans',
    component: PlansPage,
    meta: {
      title: 'Plans'
    }
  },
  {
    path: '/bulkEmail',
    name: 'BulkEmail',
    component: BulkEmailPage,
    meta: {
      title: 'Bulk Email'
    }
  },
  {
    path: '/reports/classes',
    name: 'ClassesReport',
    component: ReportsClassesPage,
    meta: {
      title: 'Classes Report'
    }
  },
  {
    path: '/reports/topStudents',
    name: 'TopStudentsReport',
    component: ReportsTopStudentsPage,
    meta: {
      title: 'Top Students Report'
    }
  },
  {
    path: '/reports/attendence',
    name: 'AttendenceReport',
    component: ReportsAttendencePage,
    meta: {
      title: 'Attendence Report'
    }
  },
  {
    path: '/reports/progress',
    name: 'SchoolProgressReport',
    component: ReportsProgressPage,
    meta: {
      title: 'School Progress Report'
    }
  },
  {
    path: '/upgrade',
    name: 'UpgradePlan',
    component: UpgradePage,
    meta: {
      title: 'Upgrade Plan'
    }
  },
  {
    path: '/ezidebit/reconcile',
    name: 'EzidebitReconcile',
    component: EziReconcilePage,
    meta: {
      title: 'Ezidebit Student Reconcile'
    }
  },
  {
    path: '/users',
    name: 'Users',
    component: UsersPage,
    meta: {
      title: 'Users'
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

trackRouter(router)

router.beforeEach(async (toRoute, fromRoute, next) => {
  let routeTitle = 'Oss Gym'

  if (toRoute.meta) {
    if (typeof toRoute.meta.title === 'string') {
      routeTitle = toRoute.meta.title + ' | ' + routeTitle
    }
  }

  window.document.title = routeTitle

  console.debug('nav', fromRoute.path, toRoute.path)
  /*
  // redirect if no auth token found
  if (!toRoute.meta.noAuth && toRoute.path !== '/login') {
    console.debug('checking token..')
    const token = await api.authClient.getToken()
    if (!token) {
      console.debug('no valid token found, redirecting..')
      next({ path: '/login' })
      return
    }
  }
  */

  next()
})

export default router
