<script lang="ts">
export default {
  props: {
    error: String,
    hideHints: Boolean
  }
}
</script>

<template>
  <div v-if="error" class="row">
    <div class="error-message red-text text-darken-4 red lighten-5">
      <p class="valign-wrapper">
        <i class="material-icons">error</i>
         <span data-test-id="generic-error-label">{{ error }}</span>
      </p>
      <ul class="browser-default" v-if="!hideHints">
        <li>The technical team has been notified.</li>
        <li>If this is an urgent issue, please get in touch.</li>
      </ul>
      <a class="btn red" href="https://m.me/ossgym" target="_blank">Get in touch</a>
    </div>
  </div>
</template>
