
<script>
import { defineComponent } from 'vue'
import ExceptionDisplay from '@/components/ExceptionDisplay.vue'
import ValidationErrorDisplay from '@/components/ValidationErrorDisplay.vue'
import LoadingDisplay from '@/components/LoadingDisplay.vue'

const vmNewClassDefinition = {
  name: '',
  isDisabled: false,
  days: [{
    name: 'Monday',
    number: 1,
    times: []
  },
  {
    name: 'Tuesday',
    number: 2,
    times: []
  },
  {
    name: 'Wednesday',
    number: 3,
    times: []
  },
  {
    name: 'Thursday',
    number: 4,
    times: []
  },
  {
    name: 'Friday',
    number: 5,
    times: []
  },
  {
    name: 'Saturday',
    number: 6,
    times: []
  },
  {
    name: 'Sunday',
    number: 0,
    times: []
  }]
}

const vmNewTimeDefinition = {
  hour: '-1',
  hourOptions: [
    { value: '01', label: '01' },
    { value: '02', label: '02' },
    { value: '03', label: '03' },
    { value: '04', label: '04' },
    { value: '05', label: '05' },
    { value: '06', label: '06' },
    { value: '07', label: '07' },
    { value: '08', label: '08' },
    { value: '09', label: '09' },
    { value: '10', label: '10' },
    { value: '11', label: '11' },
    { value: '12', label: '12' }
  ],
  minute: '-1',
  minuteOptions: [
    { value: '00', label: '00' },
    { value: '05', label: '05' },
    { value: '10', label: '10' },
    { value: '15', label: '15' },
    { value: '20', label: '20' },
    { value: '25', label: '25' },
    { value: '30', label: '30' },
    { value: '35', label: '35' },
    { value: '40', label: '40' },
    { value: '45', label: '45' },
    { value: '50', label: '50' },
    { value: '55', label: '55' }
  ],
  amPm: -1,
  amPmOptions: [
    { value: 1, label: 'AM' },
    { value: 2, label: 'PM' }
  ]
}

export default defineComponent({
  components: { ExceptionDisplay, ValidationErrorDisplay, LoadingDisplay },
  name: 'ClassesPage',
  data () {
    return {
      isLoading: false,
      allClasses: [],
      selectedClass: null,
      error: '',
      validationErrors: [],
      apiClient: {}
    }
  },

  async mounted () {
    this.apiClient = await this.$api.createApiClient()
    this.getAllClasses()
  },

  methods: {
    getAllClasses: function () {
      this.isLoading = true
      this.allClasses = []
      this.selectedClass = null
      this.error = ''
      this.validationErrors = []
      this.apiClient
        .get('classes')
        .then((r) => {
          this.allClasses = r.data
          console.debug(r.data)
        })
        .catch((e) => {
          const status = e.status || (e.response ? e.response.status : 0)
          if (status === 401) {
            this.$api.handleLoginExpired()
          } else {
            this.handleError(e)
          }
        })
        .then(() => {
          this.isLoading = false
        })
    },
    deleteClass: function (classId) {
      if (!confirm('Are you sure you want to delete this class?')) {
        return
      }

      console.debug('deleting', classId)

      this.validationErrors = []
      this.isLoading = true
      this.apiClient
        .delete('classes/' + classId)
        .then(() => {
          // refresh class list
          this.getAllClasses()
        })
        .catch((e) => {
          const status = e.status || (e.response ? e.response.status : 0)
          console.debug('error', status)
          if (status === 401) {
            this.$api.handleLoginExpired()
          } else if (status === 400) {
            this.handleBadRequest(e.response)
          } else {
            this.handleError(e)
          }
        })
        .then(() => {
          this.isLoading = false
        })
    },
    openEditForm: function (item) {
      this.selectedClass = item
      document.runMaterializeInit()
    },
    openNewForm: function () {
      const template = JSON.parse(JSON.stringify(vmNewClassDefinition))
      this.selectedClass = template
      this.error = ''
      this.validationErrors = []
      document.runMaterializeInit()
    },
    saveClass: function () {
      if (!this.selectedClass) {
        return
      }

      this.validationErrors = []
      this.isLoading = true

      // decide api method
      var relativeUri = 'classes'

      if (this.selectedClass.id) {
        relativeUri += '/' + this.selectedClass.id
      }

      console.debug('saving', this.selectedClass, relativeUri)

      this.apiClient
        .post(relativeUri, JSON.stringify(this.selectedClass))
        .then(() => {
          // refresh class list
          this.getAllClasses()
        })
        .catch((e) => {
          const status = e.status || (e.response ? e.response.status : 0)
          console.debug('error', status)
          if (status === 401) {
            this.$api.handleLoginExpired()
          } else if (status === 400) {
            this.handleBadRequest(e.response)
          } else {
            this.handleError(e)
          }
        })
        .then(() => {
          this.isLoading = false
        })
    },
    clear: function () {
      this.selectedClass = null
    },
    insertNewTime: function (day) {
      const template = Object.assign({}, vmNewTimeDefinition)
      if (!day.times) {
        day.times = []
      }
      day.times.push(template)
      document.runMaterializeInit()
    },
    removeTime: function (day, time) {
      day.times = day.times.filter(t => t !== time)
    },
    handleBadRequest: function (e) {
      console.debug('e', e)
      this.validationErrors = e.data.errors
    },
    handleError: function (e) {
      this.error = e.message
    }
  }
})
</script>

<template>

  <div class="row heading" data-test-id="page-class-list">
      <h4>Classes</h4>
  </div>

  <exception-display :error="error"></exception-display>
  <validation-error-display :validationErrors="validationErrors"></validation-error-display>
  <loading-display :isLoading="isLoading"></loading-display>

  <div v-if="!isLoading">

    <!-- class list -->
    <div v-if="!selectedClass">
      <div>
        <a class="btn blue" @click="openNewForm()" data-test-id="action-create-new-class">Create</a>
      </div>
      <table class="striped">
        <tbody>
          <tr v-for="c in allClasses" v-bind:key="c.id" :data-test-value="c.name">
              <td>
                  <h5 class="margin-medium">
                    <span data-test-id="list-item-class-name-value">{{ c.name }}</span>&nbsp;
                    <span v-if="c.isDisabled" class="grey-text" data-test-id="list-item-class-disabled-value">(disabled)</span>
                  </h5>
                  <div v-if="c.days">
                    <ul class="browser-default">
                      <div v-for="d in c.days" :key="d" :data-test-value="d.name">
                        <div v-if="d.times?.length > 0">
                          <span data-test-id="list-item-class-day-name-value">{{ d.name }}</span>
                          <ul v-if="d.times" class="browser-default">
                            <li v-for="t in d.times" :key="t" data-test-id="list-item-class-day-time-value">{{ t.formatted }}</li>
                          </ul>
                        </div>
                      </div>
                    </ul>
                  </div>
              </td>
              <td class="valign-wrapper section button-strip" data-test-value="{{c.name}}">
                <a class="btn blue" @click="openEditForm(c)" data-test-id="action-edit-class">Edit</a>
                <a v-if="c.id" class="btn red" @click="deleteClass(c.id)" data-test-id="action-delete-class">Delete</a>
              </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- create/edit form -->
    <div v-if="selectedClass" data-test-id="create-edit-modal">
      <div class="card-panel">
        <div>
          <strong>Name</strong>
          <input type="text" v-model="selectedClass.name" data-test-id="class-name-input" />
        </div>
        <div class="section">
          <label>
            <input type="checkbox" class="filled-in" v-model="selectedClass.isDisabled" data-test-id="is-disabled-input" />
            <span>Is Disabled</span>
          </label>
        </div>
      </div>
      <div>
        <h5>Days</h5>

          <div v-for="d in selectedClass.days" :key="d">

            <div class="card">
              <div class="card-content" :data-test-value="d.name">

                <span class="card-title" data-test-id="modal-day-item-name">{{ d.name }}</span>

                <table v-if="d.times?.length > 0">
                  <thead>
                    <tr>
                        <th>Hour</th>
                        <th>Minute</th>
                        <th>AM/PM</th>
                        <th></th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr v-for="t in d.times" :key="t" data-test-id="day-time-entry" :data-test-value="t.hour + ':' + t.minute + ' ' + (t.amPm == 1 ? 'AM' : (t.amPm == 2 ? 'PM' : '??'))">
                      <td>
                        <select v-model="t.hour" class="browser-default" data-test-id="day-time-hour-entry">
                          <option v-for="o in t.hourOptions" :key="o" :value="o.value" :label="o.label" />
                        </select>
                      </td>
                      <td>
                        <select v-model="t.minute" class="browser-default" data-test-id="day-time-minute-entry">
                          <option v-for="o in t.minuteOptions" :key="o" :value="o.value" :label="o.label" />
                        </select>
                      </td>
                      <td>
                        <select v-model="t.amPm" class="browser-default" data-test-id="day-time-ampm-entry">
                          <option v-for="o in t.amPmOptions" :key="o" :value="o.value" :label="o.label" />
                        </select>
                      </td>
                      <td>
                        <button @click="removeTime(d, t)" class="btn red" data-test-id="remove-time-button">Remove</button>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div>
                  <button @click="insertNewTime(d)" class="btn blue" data-test-id="add-time-button-modal">Add time</button>
                </div>
            </div>

          </div>

        </div>
      </div>

      <div class="row">
        <div class="button-strip">
          <a class="btn blue" @click="saveClass()" data-test-id="class-modal-submit-button">{{ selectedClass.id ? 'Save' : 'Create' }}</a>
          <a class="btn grey" @click="clear()">Cancel</a>
        </div>
      </div>

    </div>

  </div>

</template>
