<script>
import { defineComponent } from 'vue'
import ExceptionDisplay from '@/components/ExceptionDisplay.vue'
import ValidationErrorDisplay from '@/components/ValidationErrorDisplay.vue'
import LoadingDisplay from '@/components/LoadingDisplay.vue'

const elementIdSignUpModal = '#signUpModal'

export default defineComponent({
  components: { ExceptionDisplay, ValidationErrorDisplay, LoadingDisplay },
  name: 'LoginPage1',
  data () {
    return {
      isLoggedIn: false,
      isLoading: false,
      isRegistrationEnabled: false,
      error: '',
      validationErrors: [],
      schoolName: '',
      email: '',
      password: ''
    }
  },

  async mounted () {
    document.runMaterializeInit() // fix for modal init issue
    this.isLoggedIn = await this.$api.getIsLoggedIn()
    if (this.isLoggedIn) {
      await this.authCheck()
    } else {
      this.$api.handleLoginStateChange()
    }
  },

  methods: {
    authCheck: async function () {
      this.error = ''
      this.validationErrors = []
      this.isLoading = true
      const apiClient = await this.$api.createApiClient()
      apiClient
        .get('auth?siteKey=portal')
        .then((r) => { this.handleAuthSuccess(r) })
        .catch((e) => { this.handleFailedRequest(e) })
        .then(() => { this.isLoading = false })
    },
    login: async function () {
      this.error = ''
      this.validationErrors = []
      this.isLoading = true
      const apiClient = await this.$api.createApiClient()
      const data = {
        schoolId: this.email,
        pin: this.password
      }
      apiClient
        .post('auth?siteKey=portal', JSON.stringify(data))
        .then((r) => { this.handleAuthSuccess(r) })
        .catch((e) => { this.handleFailedRequest(e) })
        .then(() => { this.isLoading = false })
    },
    logout: async function () {
      this.error = ''
      this.validationErrors = []
      this.isLoading = true
      await this.$api.logout()
    },
    openCreateNewSchoolModal: function () {
      // this.schoolName = ''
      // document.openModal(elementIdSignUpModal)
      // this.$refs.txtSchoolName.focus()
    },
    createNewSchool: async function () {
      // this.error = ''
      // this.validationErrors = []
      // this.isLoading = true
      // await this.$api.authClient.createOrg({ org_name: this.schoolName })
    },
    handleAuthSuccess: async function (d) {
      var data = d.data
      this.$api.saveNewLogin(data)
      this.$router.push('/')
    },
    handleFailedRequest: function (e) {
      const data = this.$api.handleFailedRequest(e, false)
      this.validationErrors = data.validationErrors
      this.error = data.error
    }
  }

})
</script>

<style>
  .oss-logo-full {
    max-height: 25px;
  }
</style>

<template>
  <div class="row margin-large-top-bottom">
    <div class="col s12 l6 offset-l3">
      <div class="card-panel center-align blue darken-4" id="login-form">

        <div>
          <img src="@/assets/oss-logo-words.png" class="responsive-img oss-logo-full margin-large-top-bottom" />
        </div>

        <div class="left-align">
          <validation-error-display :validationErrors="validationErrors"></validation-error-display>
          <exception-display :error="error"></exception-display>
        </div>
        <loading-display :isLoading="isLoading" :is-white="true"></loading-display>

        <div v-if="isLoading" class="white-text">
          Logging in...
        </div>
        <div v-else class="white-text">
          <div class="margin-large-top-bottom" v-if="!isLoggedIn">
            <div class="row">
              <div class="col s6 left-align">
                <strong class="white-text">Email</strong>
                <input type="email" v-model="email" data-test-id="login-email" class="white-text" />
              </div>
              <div class="col s6 left-align">
                <strong class="white-text">Password</strong>
                <input type="password" v-model="password" data-test-id="login-password" class="white-text" />
              </div>
            </div>
            <a class="btn blue" @click="login()" data-test-id="login-form-submit">login</a>
          </div>
          <div class="margin-large-top-bottom" v-if="!isLoggedIn">
            <div v-if="isRegistrationEnabled">
              <a class="btn blue" @click="openCreateNewSchoolModal()">register</a>
            </div>
            <div v-else>
              <p>Registration is currently disabled.<br/> If you would like to give Oss Gym a try, please <a href="https://m.me/ossgym" target="_blank">get in touch</a>!</p>
            </div>
          </div>
          <div class="margin-large-top-bottom" v-if="isLoggedIn">
            <a class="btn red" @click="logout()">logout</a>
          </div>
        </div>

      </div>
    </div>
  </div>

  <!-- sign up modal -->
  <div id="signUpModal" class="modal">
    <div class="modal-content">
      <h3>Register a new school</h3>
      <div>
        <strong>Enter your school name:</strong>
        <input type="text" v-model="schoolName" ref="txtSchoolName" />
      </div>
    </div>
    <div class="modal-footer button-strip">
      <a @click="createNewSchool()" class="btn blue" :class="{ disabled: this.schoolName.replaceAll(' ', '').length < 3 }">Create!</a>
      <a href="#!" class="modal-close btn red">Cancel</a>
    </div>
  </div>

</template>
