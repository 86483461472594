<script>
import { defineComponent } from 'vue'
import ExceptionDisplay from '@/components/ExceptionDisplay.vue'
import ValidationErrorDisplay from '@/components/ValidationErrorDisplay.vue'
import LoadingDisplay from '@/components/LoadingDisplay.vue'

const sleepForMs = m => new Promise(resolve => setTimeout(resolve, m))

export default defineComponent({
  components: { ExceptionDisplay, ValidationErrorDisplay, LoadingDisplay },
  name: 'EziReconcilePage',
  data () {
    return {
      includeEndedEzidebitPlans: false,
      ezidebitCustomerFilters: [],
      isLoading: false,
      isBulkImportLoading: false,
      bulkProcessingPercentComplete: 0,
      bulkImportSuccesses: 0,
      bulkImportIgnored: 0,
      bulkImportFailures: 0,
      unmappedStudents: [],
      unmappedCustomers: [],
      plans: [],
      bulkImportPlanId: -1,
      error: '',
      validationErrors: [],
      apiClient: {}
    }
  },

  async mounted () {
    this.apiClient = await this.$api.createApiClient()
    this.getSearchParameters()
  },

  computed: {
    resultsCount: function () {
      return this.unmappedCustomers?.length || 0
    }
  },

  methods: {
    getSearchParameters: function () {
      this.isLoading = true
      this.error = ''
      this.validationErrors = []

      this.apiClient
        .get('ezidebit/reconcile/candidates')
        .then(r => {
          this.includeEndedEzidebitPlans = r.data.includeEndedEzidebitPlans
          this.ezidebitCustomerFilters = r.data.ezidebitCustomerFilters
        })
        .catch(e => {
          const status = e.status || (e.response ? e.response.status : 0)
          if (status === 401) {
            this.$api.handleLoginExpired()
          } else if (status === 400) {
            this.handleBadRequest(e)
          } else {
            this.handleError(e)
          }
        })
        .then(() => {
          this.isLoading = false
        })
    },
    getCandidates: function () {
      this.isLoading = true
      this.error = ''
      this.validationErrors = []
      // reset
      this.unmappedStudents = []
      this.unmappedCustomers = []
      this.plans = []

      var payload = {
        includeEndedEzidebitPlans: this.includeEndedEzidebitPlans,
        ezidebitCustomerFilters: this.ezidebitCustomerFilters.filter(x => x.isSelected === true).map(x => x.value)
      }

      this.apiClient
        .post('ezidebit/reconcile/candidates', JSON.stringify(payload))
        .then((r) => {
          this.unmappedStudents = r.data.unmappedStudents || []
          this.unmappedCustomers = r.data.unmappedCustomers || []
          this.plans = r.data.plans || []
          this.bulkImportPlanId = this.plans[0]?.planId || '' // default to first plan
          this.unmappedCustomers.forEach((c) => { c.selectedStudentId = c.likelyStudentId })
        })
        .catch(e => {
          const status = e.status || (e.response ? e.response.status : 0)
          if (status === 401) {
            this.$api.handleLoginExpired()
          } else if (status === 400) {
            this.handleBadRequest(e)
          } else {
            this.handleError(e)
          }
        })
        .then(() => {
          this.isLoading = false
        })
    },
    reconcile: async function (customer) {
      this.error = ''
      this.validationErrors = []
      customer.isLoading = true
      customer.error = ''

      var customerRef = customer

      var data = {
        studentId: customer.selectedStudentId, // -1 == create new student
        customerId: customer.customerId,
        planId: customer.selectedPlanId
      }

      return this.apiClient
        .post('ezidebit/reconcile', JSON.stringify(data))
        .then(() => {
          document.popToast('Successfully reconciled customer!')
          // signal that customer can be removed
          customerRef.isReconciled = true
          // remove item from list
          const index = this.unmappedCustomers.indexOf(customerRef)
          if (index > -1) {
            this.unmappedCustomers.splice(index, 1)
            console.debug('removed reconciled customer at', index)
          }
        })
        .catch((e) => {
          const status = e.status || (e.response ? e.response.status : 0)
          if (status === 401) {
            this.$api.handleLoginExpired()
          } else if (status === 400) {
            customer.error = e.response.data?.errors[0] || 'This request failed, please refresh the page and try again'
          } else {
            console.error('reconcile failed', e.message)
            customer.error = 'There was an unhandled error, please refresh the page and try again'
          }
        })
        .then(() => {
          customer.isLoading = false
        })
    },
    doBulkImport: async function (batchCount) {
      if (!confirm('Are you sure you want to bulk import using this setting?')) {
        return
      }
      this.isBulkImportLoading = true
      this.bulkProcessingPercentComplete = 1
      this.bulkImportFailures = 0
      this.bulkImportIgnored = 0
      this.bulkImportSuccesses = 0
      var processedCustomers = 0
      try {
        for (let x = 0; x < this.unmappedCustomers.length; x++) {
          await sleepForMs(200)

          this.bulkProcessingPercentComplete = Math.round(Math.max((x / this.unmappedCustomers.length) * 100, 1))

          const c = this.unmappedCustomers[x]

          // validation
          if (c.hasValidationErrors || c.selectedStudentId) {
            this.bulkImportIgnored++
            continue
          }
          // set defaults
          c.selectedStudentId = '-1' // -1 means import as new student
          c.selectedPlanId = this.bulkImportPlanId

          await this.reconcile(c)

          if (c.error) {
            this.bulkImportFailures++
          } else {
            this.bulkImportSuccesses++
          }

          processedCustomers++

          if (!!batchCount && processedCustomers >= batchCount) {
            break
          }
        }
      } catch (e) {
        console.error('bulk import error', e)
      } finally {
        this.isBulkImportLoading = false
        this.bulkProcessingPercentComplete = 100
      }
    },
    onStudentSelectChange: function (customer, eventData) {
      customer.selectedStudentId = eventData.target.value
    },
    onPlanSelectChange: function (customer, eventData) {
      customer.selectedPlanId = eventData.target.value
    },
    handleBadRequest: function (e) {
      this.validationErrors = e.response.data?.errors || ['Invalid request']
    },
    handleError: function (e) {
      this.error = e.message
    }
  }
})
</script>

<style scoped>
  td {
    vertical-align: middle;
  }
</style>

<template>

  <div class="row">
    <div class="col s12 m6">
      <h4>Ezidebit Student Reconciliation</h4>
      <h5>Read Me</h5>
      <li>We only show Ezidebit Customers that aren't already connected to a student in Oss.</li>
      <li>We only allow connecting to existing students in Oss that don't already have a plan.</li>
      <li>The Ezidebit data is pulled from your Ezidebit account, any issues with the data, please login to Ezidebit to confirm and resolve the issues, if Ezidebit looks fine, please contact Oss support.</li>
    </div>
    <div v-show="!isLoading" class="col s12 m6">
      <div class="card">
        <div class="card-content">
          <span class="card-title">Ezidebit Customer Filters</span>
          <div v-for="filter in ezidebitCustomerFilters" :key="filter.value">
            <label>
              <input v-model="filter.isSelected" type="checkbox" class="filled-in" />
              <span>{{ filter.name }}</span>
            </label>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-content">
          <label>
            <input v-model="includeEndedEzidebitPlans" type="checkbox" class="filled-in" />
            <span>Include customers with <strong>ended</strong> Ezidebit Payment Plans</span>
          </label>
        </div>
      </div>
      <div>
        <button class="btn blue" @click="getCandidates()">Search</button>
        <span class="margin-large">{{ resultsCount }} results</span>
      </div>
    </div>
  </div>

  <div v-show="unmappedCustomers.length > 0" class="row">
    <div class="card">
      <div class="card-content">
        <div v-show="!isBulkImportLoading">
          <select v-show="plans.length > 0"
                  v-model="bulkImportPlanId"
                  class="browser-default">
            <option v-for="plan in plans"
                    :key="plan.planId"
                    :value="plan.planId">
              {{ plan.planName }}
            </option>
          </select>
          <button :disabled="unmappedCustomers.length == 0" @click="doBulkImport()" class="btn blue">
            Bulk import valid customers
          </button>
        </div>
        <div v-show="isBulkImportLoading">
          <span>Bulk import valid customers</span>
          <div class="progress">
            <div class="determinate" :style="{ 'width': bulkProcessingPercentComplete + '%' }"></div>
          </div>
        </div>
        <div>
          <span class="green-text margin-medium">Successes: {{ bulkImportSuccesses }}</span>
          <span class="grey-text margin-medium">Ignored: {{ bulkImportIgnored }}</span>
          <span class="red-text margin-medium">Failures: {{ bulkImportFailures }}</span>
          <span class="grey-text margin-medium">(Scroll down to see failure reasons)</span>
        </div>
      </div>
    </div>
  </div>

  <exception-display :error="error"></exception-display>
  <validation-error-display :validationErrors="validationErrors"></validation-error-display>
  <loading-display :isLoading="isLoading"></loading-display>

  <div v-show="!isLoading">

    <div class="row" v-show="unmappedCustomers.length > 0">
      <table class="striped">
        <thead>
          <tr>
            <th>Customer</th>
            <th>Phone</th>
            <th>Email</th>
            <th>Student</th>
            <th>Plan</th>
            <th>Status</th>
            <th>Issues</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="customer in unmappedCustomers.filter(c => !c.isReconciled)" :key="customer.customerId">
            <td>{{ customer.fullName }}</td>
            <td>{{ customer.phone }}</td>
            <td>{{ customer.email }}</td>
            <td>
              <div class="input-field">
                <select v-if="unmappedStudents.length > 0"
                        v-model="customer.selectedStudentId"
                        class="browser-default">
                  <option v-for="student in unmappedStudents"
                          :key="student.studentId"
                          :value="student.studentId">
                    {{ student.fullName }}
                  </option>
                </select>
              </div>
            </td>
            <td>
              <div class="input-field">
                <select v-if="plans.length > 0"
                        v-model="customer.selectedPlanId"
                        class="browser-default">
                  <option v-for="plan in plans"
                          :key="plan.planId"
                          :value="plan.planId">
                    {{ plan.planName }}
                  </option>
                </select>
              </div>
            </td>
            <td>
              {{ customer.ezidebitStatus }}
            </td>
            <td>
              <div class="input-field">
                <ul v-if="customer.hasValidationErrors">
                  <li v-for="error in customer.validationErrors" :key="error" class="red-text">
                    {{ error }}
                  </li>
                </ul>
              </div>
            </td>
            <td>
              <button @click="reconcile(customer)"
                      class="btn blue"
                      :class="{ disabled: customer.isLoading || !customer.selectedStudentId || !customer.selectedPlanId || customer.hasValidationErrors }">
                Connect
              </button>
              <div v-if="customer.isLoading" class="lds-dual-ring"></div>
              <div v-if="customer.error">
                <span class="red-text valign-wrapper">
                  <i class="material-icons tiny margin-medium-right">error</i>
                  {{ customer.error }}
                </span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row" v-if="!unmappedCustomers">
        <h5>Nothing from Ezidebit to map!</h5>
    </div>
  </div>

</template>
