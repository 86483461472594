<script>
import { defineComponent } from 'vue'
import ExceptionDisplay from '@/components/ExceptionDisplay.vue'
import ValidationErrorDisplay from '@/components/ValidationErrorDisplay.vue'
import LoadingDisplay from '@/components/LoadingDisplay.vue'
import SiteImageUploadField from '@/components/SiteImageUploadField.vue'

const vmNewClassItemTemplate = {
  title: '',
  subTitle: '',
  content: '',
  photoUrl: ''
}
const vmNewInstructorItemTemplate = {
  title: '',
  subTitle: '',
  content: '',
  photoUrl: ''
}
const vmNewLocationItemTemplate = {
  name: '',
  fullAddress: '',
  googleMapsEmbedUrl: ''
}
const vmNewCarouselItemTemplate = {
  photoUrl: ''
}
const vmNewContentItemTemplate = {
  title: '',
  content: ''
}

export default defineComponent({
  components: { ExceptionDisplay, ValidationErrorDisplay, LoadingDisplay, SiteImageUploadField },
  name: 'SettingsWebsitePage',
  data () {
    return {
      error: '',
      validationErrors: [],
      isLoading: false,
      apiClient: {},
      siteSettings: null
    }
  },

  async mounted () {
    document.runMaterializeInit() // tabs don't work unless running this
    this.apiClient = await this.$api.createApiClient()
    this.loadSiteSettings()
  },

  methods: {
    loadSiteSettings: function () {
      this.error = ''
      this.validationErrors = []
      this.isLoading = true
      this.siteSettings = null
      this.apiClient
        .get('settings/site')
        .then(r => { this.siteSettings = r.data })
        .catch(e => this.handleRequestFailure(e))
        .then(() => { this.isLoading = false })
    },
    saveSiteSettings: function (publish) {
      this.error = ''
      this.validationErrors = []
      this.isLoading = true
      var shouldPublish = publish
      this.siteSettings.triggerSiteRegen = shouldPublish
      const data = JSON.stringify(this.siteSettings)
      this.apiClient
        .put('settings/site', data)
        .then(() => {
          this.loadSiteSettings()
          if (shouldPublish) {
            document.popToast('Publish has started, this usually takes around 5 minutes for changes to show on your website.')
          }
        })
        .catch(e => this.handleRequestFailure(e))
        .then(() => { this.isLoading = false })
    },
    handleRequestFailure: function (e) {
      const status = e.status || (e.response ? e.response.status : 0)
      if (status === 401) {
        this.$api.handleLoginExpired()
      } else if (status === 400) {
        this.validationErrors = (e.response?.data?.errors || ['Unexpected error'])
      } else {
        this.error = (e.response?.data?.error || 'Unexpected error')
      }
    }
  }

})
</script>

<style scoped>

  textarea {
    min-height: 100px;
  }

  .settings-section {
      margin-bottom: 22px;
  }

  .settings-section label {
      color: black;
  }

  .tabs .tab a {
    color: #0D47A1;
  }
  .tabs .tab a:focus, .tabs .tab a:focus.active {
    background-color: #b3e5fc;
  }

  .tabs .indicator {
    background-color: #0D47A1 !important;
  }

</style>
<template>

  <div>
    <div class="row heading">
        <h4>School Website Settings</h4>
        <p class="grey-text">Customize your website school to your preferences</p>
    </div>

    <div class="row">
      <div class="col s12">
        <ul class="tabs">
          <li class="tab col s2"><a href="#general">General</a></li>
          <li class="tab col s2"><a href="#homePage">Home Page</a></li>
          <li class="tab col s2"><a href="#classesPage">Class Page</a></li>
          <li class="tab col s2"><a href="#aboutPage">About Page</a></li>
          <li class="tab col s2"><a href="#contactPage">Contact Page</a></li>
          <li class="tab col s2"><a href="#theme">Theme</a></li>
        </ul>
      </div>
    </div>

    <div>
      <validation-error-display :validationErrors="validationErrors"></validation-error-display>
      <exception-display :error="error"></exception-display>
      <loading-display :isLoading="isLoading"></loading-display>
    </div>

    <div class="row">

      <!-- general -->
      <div id="general" class="col s12">
        <div class="row" v-if="!isLoading && siteSettings">
          <h4>General</h4>

          <!-- site -->
          <div class="col s12">
            <h5>Site</h5>
            <div class="row card-panel">

              <div class="col s12 m12 l6 settings-section">
                <div>
                  <strong>Website Name</strong>
                </div>
                <div>
                  <input type="text" v-model="siteSettings.data.schoolName" />
                </div>
              </div>

              <div class="col s12 m12 l6 settings-section">
                <div>
                  <strong>Website Url</strong>
                </div>
                <div>
                  <input type="text" v-model="siteSettings.data.websiteUrl" />
                </div>
              </div>

              <div class="col s12 m12 l6 settings-section">
                <div>
                  <strong>Logo</strong>
                </div>
                <div>
                  <site-image-upload-field v-model="siteSettings.data.logoPhotoUrl" :imageBaseUrl="siteSettings.filesBaseDir" />
                </div>
              </div>
            </div>
          </div>

          <!-- social -->
          <div class="col s12">
            <h5>Social</h5>
            <div class="row card-panel">

              <div class="col s12 m12 l6 settings-section">
                <div>
                  <strong>Facebook Url</strong>
                </div>
                <div>
                  <input type="text" v-model="siteSettings.data.facebookUrl" />
                </div>
              </div>

              <div class="col s12 m12 l6 settings-section">
                <div>
                  <strong>Instagram Url</strong>
                </div>
                <div>
                  <input type="text" v-model="siteSettings.data.instagramUrl" />
                </div>
              </div>

              <div class="col s12 m12 l6 settings-section">
                <div>
                  <strong>Sharing Card Title</strong>
                </div>
                <div>
                  <input type="text" v-model="siteSettings.data.seoMetadata.title" />
                </div>
              </div>

              <div class="col s12 m12 l6 settings-section">
                <div>
                  <strong>Sharing Card Description</strong>
                </div>
                <div>
                  <textarea v-model="siteSettings.data.seoMetadata.desc" class="browser-default"></textarea>
                </div>
              </div>

              <div class="col s12 m12 l6 settings-section">
                <div>
                  <strong>Sharing Card Screenshot</strong>
                </div>
                <div>
                  <site-image-upload-field v-model="siteSettings.data.seoMetadata.photoUrl" :imageBaseUrl="siteSettings.filesBaseDir" />
                </div>
              </div>
            </div>
          </div>

          <!-- locations -->
          <div class="col s12">
            <h5>Gym Locations</h5>

            <div class="row card-panel" v-for="l in siteSettings.data.locations" :key="l">
              <div class="col s12 m12 l6 settings-section">
                <div>
                  <strong>Name</strong> (e.g. Headquarters, etc)
                </div>
                <div>
                  <input type="text" v-model="l.name" />
                </div>
              </div>

              <div class="col s12 m12 l6 settings-section">
                <div>
                  <strong>Full Address</strong>
                </div>
                <div>
                  <input type="text" v-model="l.fullAddress" />
                </div>
              </div>

              <div class="col s12 m12 l6 settings-section">
                <div>
                  <strong>Google Maps Embed Url (<a href="/help/gmaps-url-help.png" target="_blank">help</a>)</strong>
                </div>
                <div>
                  <input type="text" v-model="l.googleMapsEmbedUrl" />
                </div>
              </div>

              <div class="col s12 settings-section">
                <a class="btn red right" @click="siteSettings.data.locations = siteSettings.data.locations.filter(x => x.name !== l.name)">Delete Location</a>
              </div>
            </div>

            <a class="btn blue" @click="siteSettings.data.locations.push(Object.assign({}, vmNewLocationItemTemplate))">Add Location</a>

          </div>
        </div>

      </div>

      <!-- home page -->
      <div id="homePage" class="col s12">
        <h4>Home Page</h4>

        <div class="row" v-if="!isLoading && siteSettings">

          <!-- header -->
          <div class="col s12">
            <h5>Header</h5>
            <div class="row card-panel">

              <div class="col s12 m12 l6 settings-section">
                <div>
                  <strong>Header Title</strong>
                </div>
                <div>
                  <input type="text" v-model="siteSettings.data.homePage.headerTitle" />
                </div>
              </div>

              <div class="col s12 m12 l6 settings-section">
                <div>
                  <strong>Header Subtitle</strong>
                </div>
                <div>
                  <input type="text" v-model="siteSettings.data.homePage.headerSubTitle" />
                </div>
              </div>

              <div class="col s12 m12 l6 settings-section">
                <div>
                  <strong>Header Background Image</strong>
                </div>
                <div>
                  <site-image-upload-field v-model="siteSettings.data.homePage.headerPhotoUrl" :imageBaseUrl="siteSettings.filesBaseDir" />
                </div>
              </div>

            </div>
          </div>

          <!-- content -->
          <div class="col s12">
            <h5>Content</h5>

            <div class="row card-panel" v-for="c in siteSettings.data.homePage.contentItems" :key="c">

              <div class="col s12 l6 settings-section">
                <div>
                  <strong>Title</strong>
                </div>
                <div>
                  <input type="text" v-model="c.title" />
                </div>
              </div>

              <div class="col s12 l6 settings-section">
                <div>
                  <strong>Content</strong>
                </div>
                <div>
                  <textarea v-model="c.content" class="browser-default"></textarea>
                </div>
              </div>

              <div class="col s12 settings-section">
                <a class="btn red right" @click="siteSettings.data.homePage.contentItems = siteSettings.data.homePage.contentItems.filter(x => x.title !== c.title)">Delete Content</a>
              </div>
            </div>

            <a class="btn blue" @click="siteSettings.data.homePage.contentItems.push(Object.assign({}, vmNewContentItemTemplate))">Add Content</a>
          </div>

          <!-- CTA -->
          <div class="col s12">
            <h5>Call To Action</h5>

            <div class="row card-panel">

              <div class="col s12 m12 l6 settings-section">
                <div>
                  <strong>Cta Title</strong>
                </div>
                <div>
                  <input type="text" v-model="siteSettings.data.homePage.ctaTitle" />
                </div>
              </div>

              <div class="col s12 m12 l6 settings-section">
                <div>
                  <strong>Cta Subtitle</strong>
                </div>
                <div>
                  <input type="text" v-model="siteSettings.data.homePage.ctaSubtitle" />
                </div>
              </div>

              <div class="col s12 m12 l6 settings-section">
                <div>
                  <strong>Cta Background Image</strong>
                </div>
                <div>
                  <site-image-upload-field v-model="siteSettings.data.homePage.ctaBackgroundPhotoUrl" :imageBaseUrl="siteSettings.filesBaseDir" />
                </div>
              </div>

            </div>
          </div>

          <!-- Carousel -->
          <div class="col s12">
            <h5>Image Carousel</h5>

            <div class="row card-panel">

              <div class="col s12 m6 l4 settings-section" v-for="c in siteSettings.data.homePage.carouselItems" :key="c">
                <div>
                  <div>
                    <strong>Image</strong>
                  </div>
                  <div>
                    <site-image-upload-field v-model="c.photoUrl" :imageBaseUrl="siteSettings.filesBaseDir" :hideDeleteButton="true" />
                  </div>
                  <a class="btn red" @click="siteSettings.data.homePage.carouselItems = siteSettings.data.homePage.carouselItems.filter(x => x.photoUrl !== c.photoUrl)">Delete Image</a>
                </div>
              </div>

            </div>

            <a class="btn blue" @click="siteSettings.data.homePage.carouselItems.push(Object.assign({}, vmNewCarouselItemTemplate))">Add Image</a>

          </div>

        </div>

      </div>

      <!-- classes page -->
      <div id="classesPage" class="col s12">
        <h4>Classes Page</h4>

        <div class="row" v-if="!isLoading && siteSettings">

          <!-- header -->
          <div class="col s12">
            <h5>Header</h5>
            <div class="row card-panel">

              <div class="col s12 m12 l6 settings-section">
                <div>
                  <strong>Header Background Image</strong>
                </div>
                <div>
                  <site-image-upload-field v-model="siteSettings.data.classesPage.headerPhotoUrl" :imageBaseUrl="siteSettings.filesBaseDir" />
                </div>
              </div>

            </div>
          </div>

          <!-- list -->
          <div class="col s12">
            <h5>Classes</h5>

            <div class="row card-panel" v-for="c in siteSettings.data.classesPage.classes" :key="c">

              <div class="col s12 l6 settings-section">
                <div>
                  <strong>Class Title</strong>
                </div>
                <div>
                  <input type="text" v-model="c.title" />
                </div>
              </div>

              <div class="col s12 l6 settings-section">
                <div>
                  <strong>Class SubTitle</strong>
                </div>
                <div>
                  <input type="text" v-model="c.subTitle" />
                </div>
              </div>

              <div class="col s12 l6 settings-section">
                <div>
                  <strong>Class Content</strong>
                </div>
                <div>
                  <textarea v-model="c.content" class="browser-default"></textarea>
                </div>
              </div>

              <div class="col s12 l6 settings-section">
                <div>
                  <strong>Class Image</strong>
                </div>
                <div>
                  <site-image-upload-field v-model="c.photoUrl" :imageBaseUrl="siteSettings.filesBaseDir" />
                </div>
              </div>

              <div class="col s12 settings-section">
                <a class="btn red right" @click="siteSettings.data.classesPage.classes = siteSettings.data.classesPage.classes.filter(x => x.title !== c.title)">Delete Class</a>
              </div>
            </div>

            <a class="btn blue" @click="siteSettings.data.classesPage.classes.push(Object.assign({}, vmNewClassItemTemplate))">Add Class</a>

          </div>

          <!-- timetable -->
          <div class="col s12">
            <h5>Timetable</h5>
            <div class="row card-panel">

              <div class="col s12 m12 l6 settings-section">
                <div>
                  <strong>Image</strong>
                </div>
                <div>
                  <site-image-upload-field v-model="siteSettings.data.classesPage.timetablePhotoUrl" :imageBaseUrl="siteSettings.filesBaseDir" />
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>

      <!-- about page -->
      <div id="aboutPage" class="col s12">
        <div class="row" v-if="!isLoading && siteSettings">
          <h4>About Page</h4>

          <!-- header -->
          <div class="col s12">
            <h5>Header</h5>
            <div class="row card-panel">

              <div class="col s12 m12 l6 settings-section">
                <div>
                  <strong>Header Background Image</strong>
                </div>
                <div>
                  <site-image-upload-field v-model="siteSettings.data.aboutPage.headerPhotoUrl" :imageBaseUrl="siteSettings.filesBaseDir" />
                </div>
              </div>

            </div>
          </div>

          <!-- history -->
          <div class="col s12">
            <h5>History</h5>
            <div class="row card-panel">

              <div class="col s12 m12 l6 settings-section">
                <div>
                  <strong>History Image</strong>
                </div>
                <div>
                  <site-image-upload-field v-model="siteSettings.data.aboutPage.historyPhotoUrl" :imageBaseUrl="siteSettings.filesBaseDir" />
                </div>
              </div>

              <div class="col s12 m12 l6 settings-section">
                <div>
                  <strong>History Content</strong>
                </div>
                <div>
                  <textarea v-model="siteSettings.data.aboutPage.historyContent" class="browser-default"></textarea>
                </div>
              </div>
            </div>
          </div>

          <!-- instructors -->
          <div class="col s12">
            <h5>Instructors</h5>

            <div class="row card-panel" v-for="i in siteSettings.data.aboutPage.instructors" :key="i">

              <div class="col s12 l6 settings-section">
                <div>
                  <strong>Instructor Name</strong>
                </div>
                <div>
                  <input type="text" v-model="i.title" />
                </div>
              </div>

              <div class="col s12 l6 settings-section">
                <div>
                  <strong>Instructor SubTitle</strong>
                </div>
                <div>
                  <input type="text" v-model="i.subTitle" />
                </div>
              </div>

              <div class="col s12 l6 settings-section">
                <div>
                  <strong>Instructor Content</strong>
                </div>
                <div>
                  <textarea v-model="i.content" class="browser-default"></textarea>
                </div>
              </div>

              <div class="col s12 l6 settings-section">
                <div>
                  <strong>Instructor Image</strong>
                </div>
                <div>
                  <site-image-upload-field v-model="i.photoUrl" :imageBaseUrl="siteSettings.filesBaseDir" />
                </div>
              </div>

              <div class="col s12 settings-section">
                <a class="btn red right" @click="siteSettings.data.aboutPage.instructors = siteSettings.data.aboutPage.instructors.filter(x => x.title !== i.title)">Delete Instructor</a>
              </div>
            </div>

            <a class="btn blue" @click="siteSettings.data.aboutPage.instructors.push(Object.assign({}, vmNewInstructorItemTemplate))">Add Instructor</a>
          </div>

          <!-- carousel -->
          <div class="col s12">
            <h5>Carousel</h5>

            <div class="row card-panel">

              <div class="col s12 m6 l4 settings-section" v-for="c in siteSettings.data.aboutPage.carouselItems" :key="c">
                <div>
                  <div>
                    <strong>Image</strong>
                  </div>
                  <div>
                    <site-image-upload-field v-model="c.photoUrl" :imageBaseUrl="siteSettings.filesBaseDir" :hideDeleteButton="true" />
                  </div>
                  <a class="btn red" @click="siteSettings.data.aboutPage.carouselItems = siteSettings.data.aboutPage.carouselItems.filter(x => x.photoUrl !== c.photoUrl)">Delete Image</a>
                </div>
              </div>

              </div>

              <a class="btn blue" @click="siteSettings.data.aboutPage.carouselItems.push(Object.assign({}, vmNewCarouselItemTemplate))">Add Image</a>

            </div>

        </div>
      </div>

      <!-- contact page -->
      <div id="contactPage" class="col s12">
        <div class="row" v-if="!isLoading && siteSettings">
          <h4>Contact Page</h4>

          <!-- header -->
          <div class="col s12">
            <h5>Header</h5>
            <div class="row card-panel">

              <div class="col s12 m12 l6 settings-section">
                <div>
                  <strong>Header Background Image</strong>
                </div>
                <div>
                  <site-image-upload-field v-model="siteSettings.data.contactPage.headerPhotoUrl" :imageBaseUrl="siteSettings.filesBaseDir" />
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>

      <!-- theme -->
      <div id="theme" class="col s12">
        <div class="row" v-if="!isLoading && siteSettings">
          <h4>Theme</h4>

          <div class="card-panel red lighten-5" v-if="!siteSettings.canCustomiseTheme">If you'd like to tweak these settings, please <a href="https://m.me/ossgym" target="_blank">contact support</a>.</div>

          <!-- colors -->
          <div class="col s12">
            <h5>Colors</h5>
            <div class="row card-panel">

              <div class="col s12 m12 l6 settings-section">
                <div>
                  <strong>Primary Color</strong>
                </div>
                <div>
                  <input type="text" v-model="siteSettings.data.theme.primaryColor" :disabled="!siteSettings.canCustomiseTheme ? 'true' : null" />
                </div>
              </div>

              <div class="col s12 m12 l6 settings-section">
                <div>
                  <strong>Button background color (Must be in hexidecimal format e.g. #XXXXXX)</strong>
                </div>
                <div>
                  <input type="text" v-model="siteSettings.data.theme.ctaPrimaryColor" :disabled="!siteSettings.canCustomiseTheme ? 'true' : null" />
                </div>
              </div>

              <div class="col s12 m12 l6 settings-section">
                <div>
                  <strong>Button text color (Must be in hexidecimal format e.g. #XXXXXX)</strong>
                </div>
                <div>
                  <input type="text" v-model="siteSettings.data.theme.ctaSecondaryColor" :disabled="!siteSettings.canCustomiseTheme ? 'true' : null" />
                </div>
              </div>

              <div class="col s12 m12 l6 settings-section">
                <div>
                  <strong>Use Header Text Boxing (The colored square around headings)</strong>
                </div>
                <div>
                  <div class="input-field">
                    <p>
                      <label>
                        <input v-model="siteSettings.data.theme.useHeaderTextBoxing" type="checkbox" class="filled-in is-ongoing-toggle" :disabled="!siteSettings.canCustomiseTheme ? 'true' : null" />
                        <span></span>
                      </label>
                    </p>
                  </div>
                </div>
              </div>

              <div class="col s12 m12 l6 settings-section">
                <div>
                  <strong>Overlay Opacity (how strong the color is that goes over photos, between 0-1)</strong>
                </div>
                <div>
                  <input type="text" v-model="siteSettings.data.theme.overlayOpacity" :disabled="!siteSettings.canCustomiseTheme ? 'true' : null" />
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>

    </div>

    <div class="center-align" v-if="!isLoading && siteSettings">
      <hr />
      <div class="w-50">
        <div class="card-panel button-strip">
          <a class="btn waves-effect waves-blue blue" @click="saveSiteSettings(false)">Save</a>
          <a class="btn waves-effect waves-blue blue" @click="saveSiteSettings(true)">Save and Publish</a>
          <div>
            <span>Note: Site can only be published at most once per 5 minutes</span>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
