<script>
import { defineComponent } from 'vue'
import Chart from 'chart.js'
import ExceptionDisplay from '@/components/ExceptionDisplay.vue'
import ValidationErrorDisplay from '@/components/ValidationErrorDisplay.vue'
import LoadingDisplay from '@/components/LoadingDisplay.vue'

export default defineComponent({
  components: { ExceptionDisplay, ValidationErrorDisplay, LoadingDisplay },
  name: 'ProgressPage',
  data () {
    return {
      error: '',
      validationErrors: [],
      isLoading: false,
      apiClient: {},
      chartData: {
        datasets: [],
        labels: []
      },
      monthsQuery: 3 // default 3 months of data
    }
  },

  async created () {
    this.apiClient = await this.$api.createApiClient()
    this.loadGraphs()
  },

  computed: {
    hasChartData: function () {
      var hasData = false

      if (this.chartData && this.chartData.datasets) {
        hasData = (this.chartData.datasets.length > 0)
      }

      console.debug('has data', hasData)

      return hasData
    }
  },

  methods: {
    loadGraphs: function () {
      this.error = ''
      this.validationErrors = []
      this.isLoading = true
      if (!this.monthsQuery) {
        this.monthsQuery = 1
      }
      const data = JSON.stringify({ dataMonths: this.monthsQuery })
      this.apiClient
        .post('reports/schoolProgress', data)
        .then((r) => {
          this.chartData = r.data.chartData
          this.displayChart()
        })
        .catch((e) => {
          const status = e.status || (e.response ? e.response.status : 0)
          if (status === 401) {
            this.$api.handleLoginExpired()
          } else {
            this.handleError(e)
          }
        })
        .then(() => {
          this.isLoading = false
        })
    },
    handleBadRequest: function (e) {
      if (e.data.Message) {
        this.validationErrors = [e.data.Message]
      } else {
        this.validationErrors = e.data.errors
      }
    },
    handleError: function (e) {
      this.error = e.message
    },
    displayChart: function () {
      try {
        const ctx = document.getElementById('myChart')
        var myBarChart = new Chart(ctx, {
          type: 'line',
          data: this.chartData,
          options: {
            scales: {
              yAxes: [{
                ticks: {
                  min: 0
                }
              }]
            }
          }
        })
      } catch (e) {
        console.debug(e)
      }
    }
  }

})
</script>

<style scoped>
  .settings-section {
      margin-bottom: 22px;
  }

  .settings-section label {
      color: black;
  }
</style>

<template>

  <div class="row heading">
    <h4>School Progress</h4>
    <p class="grey-text">Track how your school is doing over time</p>
  </div>

  <validation-error-display :validationErrors="validationErrors"></validation-error-display>
  <exception-display :error="error"></exception-display>
  <loading-display :isLoading="isLoading"></loading-display>

  <div v-show="!isLoading">

    <div class="row">
      <div class="col s12 l6">
        <div>
          <strong>Months</strong>
          <input v-model="monthsQuery" class="browser-default" />
        </div>
      </div>
      <div class="col s12 l6">
          <a @click="loadGraphs()" class="btn waves-effect waves-blue blue right">Show</a>
      </div>
    </div>

    <!-- chart -->
    <div class="row">
      <div class="col s12 center-align card-panel">

          <div class="section">
              <h6>ASV over {{ monthsQuery }} months</h6>
          </div>

          <div v-if="!hasChartData">No results found</div>

          <div v-show="hasChartData">
            <div id="chartContainer" class="chart-container" style="position: relative;">
                <canvas id="myChart"></canvas>
            </div>
          </div>

          <div class="section grey-text">
            Annual Student Value (ASV) is defined as the total income if all students kept renewing their current plans over the period of a year
          </div>
      </div>
    </div>

  </div>

</template>
