<script>
import { defineComponent } from 'vue'
import ExceptionDisplay from '@/components/ExceptionDisplay.vue'
import ValidationErrorDisplay from '@/components/ValidationErrorDisplay.vue'
import LoadingDisplay from '@/components/LoadingDisplay.vue'

export default defineComponent({
  components: { ExceptionDisplay, ValidationErrorDisplay, LoadingDisplay },
  name: 'SettingsPage',
  data () {
    return {
      error: '',
      validationErrors: [],
      isLoading: false,
      apiClient: {},
      settings: {}
    }
  },

  async created () {
    this.apiClient = await this.$api.createApiClient()
    this.loadSchoolSettings()
  },

  methods: {
    loadSchoolSettings: function () {
      this.error = ''
      this.validationErrors = []
      this.isLoading = true
      this.settings = null
      this.apiClient
        .get('settings')
        .then((r) => {
          this.settings = r.data
          console.debug('settings', this.settings)
        })
        .catch((e) => {
          const status = e.status || (e.response ? e.response.status : 0)
          if (status === 401) {
            this.$api.handleLoginExpired()
          } else {
            this.handleError(e)
          }
        })
        .then(() => {
          this.isLoading = false
        })
    },
    saveSchoolSettings: function () {
      this.error = ''
      this.validationErrors = []
      this.isLoading = true
      const data = JSON.stringify(this.settings)
      this.apiClient
        .post('settings', data)
        .then((r) => {
          // console.debug('settings save response', r)
        })
        .catch((e) => {
          const status = e.status || (e.response ? e.response.status : 0)
          console.debug('status', status)
          if (status === 401) {
            this.$api.handleLoginExpired()
          } else if (status === 400) {
            this.handleBadRequest(e.response)
          } else {
            this.handleError(e)
          }
        })
        .then(() => {
          this.isLoading = false
        })
    },
    handleBadRequest: function (e) {
      if (e.data.Message) {
        this.validationErrors = [e.data.Message]
      } else {
        this.validationErrors = e.data.errors
      }
    },
    handleError: function (e) {
      this.error = e.message
    },
    uploadSchoolImage: function (event) {
      this.error = ''
      this.validationErrors = []
      this.settings.photoUrl = '' // fix binding bug
      this.isLoading = true
      const data = new FormData()
      data.append('file', event.target.files[0])
      const config = {
        header: {
          'Content-Type': 'image/png'
        }
      }
      // upload
      this.apiClient
        .post('settings/photo/school', data, config)
        .then(() => {
          this.isLoading = false
          this.loadSchoolSettings()
        })
        .catch((e) => {
          const status = e.status || (e.response ? e.response.status : 0)
          console.debug('status', status)
          if (status === 401) {
            this.$api.handleLoginExpired()
          } else if (status === 400) {
            this.handleBadRequest(e.response)
          } else {
            this.handleError(e)
          }
        })
        .then(() => {
          this.isLoading = false
        })
    },
    deleteSchoolImage: function () {
      this.error = ''
      this.validationErrors = []
      this.settings.photoUrl = '' // fix binding bug
      this.isLoading = true
      this.apiClient
        .delete('settings/photo/school')
        .then(() => {
          this.isLoading = false
          this.loadSchoolSettings()
        })
        .catch((e) => {
          const status = e.status || (e.response ? e.response.status : 0)
          console.debug('status', status)
          if (status === 401) {
            this.$api.handleLoginExpired()
          } else if (status === 400) {
            this.handleBadRequest(e.response)
          } else {
            this.handleError(e)
          }
        })
        .then(() => {
          this.isLoading = false
        })
    },
    uploadScreensaverImage: function (event) {
      this.error = ''
      this.validationErrors = []
      this.settings.screensaverPhotoUrl = '' // fix binding bug
      this.isLoading = true
      const data = new FormData()
      data.append('file', event.target.files[0])
      const config = {
        header: {
          'Content-Type': 'image/png'
        }
      }
      // upload
      this.apiClient
        .post('settings/photo/screensaver', data, config)
        .then(() => {
          this.isLoading = false
          this.loadSchoolSettings()
        })
        .catch((e) => {
          const status = e.status || (e.response ? e.response.status : 0)
          console.debug('status', status)
          if (status === 401) {
            this.$api.handleLoginExpired()
          } else if (status === 400) {
            this.handleBadRequest(e.response)
          } else {
            this.handleError(e)
          }
        })
        .then(() => {
          this.isLoading = false
        })
    },
    deleteScreensaverImage: function () {
      this.error = ''
      this.validationErrors = []
      this.settings.screensaverPhotoUrl = '' // fix binding bug
      this.isLoading = true
      this.apiClient
        .delete('settings/photo/screensaver')
        .then(() => {
          this.isLoading = false
          this.loadSchoolSettings()
        })
        .catch((e) => {
          const status = e.status || (e.response ? e.response.status : 0)
          console.debug('status', status)
          if (status === 401) {
            this.$api.handleLoginExpired()
          } else if (status === 400) {
            this.handleBadRequest(e.response)
          } else {
            this.handleError(e)
          }
        })
        .then(() => {
          this.isLoading = false
        })
    }
  }

})
</script>

<style scoped>
  .settings-section {
      margin-bottom: 22px;
  }

  .settings-section label {
      color: black;
  }
</style>

<template>

  <validation-error-display :validationErrors="validationErrors"></validation-error-display>
  <exception-display :error="error"></exception-display>
  <loading-display :isLoading="isLoading"></loading-display>

  <div v-if="!isLoading && settings">
    <div class="row heading">
        <h4>School Settings</h4>
        <p class="grey-text">Customize your school to your preferences</p>
        <p><small class="grey-text">school id: {{ settings.id }}</small></p>
    </div>

    <div class="row">
      <div class="col s12 settings-section">
        <router-link to="/settings/website" class="btn blue margin-medium-right" v-if="settings.isSiteGenEnabled">
          <i class="material-icons left">input</i> Website Settings
        </router-link>
        <router-link to="/ezidebit/reconcile" class="btn blue" v-if="settings.isEzidebitConnected">
          <i class="material-icons left">input</i> Ezidebit Reconcile
        </router-link>
      </div>
    </div>

    <div>
      <label asp-for="ImageUpload"></label>
      <div class="center-align">

        <div>
          <img id="photo-fallback"
                src="@/assets/school-placeholder.jpg"
                class="responsive-img circle z-depth-1 photo hide" />
          <img :src="settings.photoUrl"
                onerror="this.onerror=null;this.src=document.getElementById('photo-fallback').src;"
                class="responsive-img circle z-depth-1 photo" />
        </div>

        <div class="section settings-section">
          <div class="center-align">
            <div class="file-field" style="width: 98px; display: inline-block;">
              <div class="btn blue">
                <span>Upload</span>
                <input class="text-box single-line" id="ImageUpload" name="ImageUpload" type="file" accept=".jpeg, .jpg, .gif, .png" @change="uploadSchoolImage($event)" />
              </div>
              <div class="file-path-wrapper">
                <input class="file-path validate" type="text">
              </div>
            </div>
          </div>
          <button class="btn red" @click="deleteSchoolImage()">Delete</button>
        </div>

      </div>
    </div>

    <div class="row">

      <div class="col s12 m12 l6 settings-section">
        <div>
          <strong>Name</strong>
        </div>
        <div>
          <input name="Name" type="text" v-model="settings.name" />
        </div>
      </div>

      <div class="col s12 m12 l6 settings-section">
        <div>
          <strong>Email</strong>
        </div>
        <div>
          <input name="Email" type="text" v-model="settings.email" />
        </div>
      </div>

      <div class="col s12 m12 l6 settings-section">
        <div>
          <strong>Pin</strong>
        </div>
        <div>
          <input name="Pin" type="text" v-model="settings.pin" />
        </div>
      </div>

      <div class="col s12 m12 l6 settings-section">
        <div>
          <strong>Check In Buffer</strong>
        </div>
        <div>
          <input name="CheckInBuffer" type="number" v-model="settings.checkInBuffer" />
        </div>
      </div>

      <div class="col s12 m12 l6">
        <div class="settings-section">
          <div>
            <strong>Country Name</strong>
          </div>
          <div>
            {{ settings.countryName }}
          </div>
        </div>
        <div class="settings-section">
          <div>
            <strong>Timezone</strong>
          </div>
          <div>
            {{ settings.timezoneKey }}
          </div>
        </div>
      </div>

      <div class="col s12 m12 l6">
        <div class="settings-section">
          <label>
            <input class="filled-in" name="RequireClassOnCheckIn" type="checkbox" v-model="settings.requireClassOnCheckIn" />
              <span>Require Class On Sign In</span>
          </label>
        </div>
        <div class="settings-section">
          <label>
              <input class="filled-in" name="IsShowPaymentDueWarningOnCheckIn" type="checkbox" v-model="settings.isShowPaymentDueWarningOnCheckIn" />
              <span>Show Payment Warning On Sign In</span>
          </label>
        </div>
        <div class="settings-section">
          <label>
            <input class="filled-in" name="SendWeeklyEmail" type="checkbox" v-model="settings.sendWeeklyEmail" />
            <span>Send Weekly Activity Email</span>
          </label>
        </div>
      </div>

      <div class="col s12 m12 l6 settings-section">
        <div>
          <strong>Linked School Id (advanced)</strong>
        </div>
        <div>
          <input name="LinkedSchoolId" type="text" v-model="settings.linkedSchoolId" />
        </div>
      </div>

      <div class="col s12 m12 l6 settings-section">
        <div>
          <strong>Student trial length (days)</strong>
        </div>
        <div>
            <input name="TrialLengthInDays" type="number" v-model="settings.trialLengthInDays" />
        </div>
      </div>

      <div class="col s12 m12 l6 settings-section">
        <div>
          <strong>Days since last sign in that students marked as disengaged</strong>
        </div>
        <div>
            <input name="DisengagedInDays" type="number" v-model="settings.disengagedInDays" />
        </div>
      </div>

    </div>

    <div class="row">
      <div class="col s12 l10 offset-l1 center-align">

        <div class="section grey lighten-4">
          <p><strong>Sign In Kiosk Screensaver Photo</strong></p>

          <div v-if="settings.screensaverPhotoUrl">
            <a v-bind:href="settings.screensaverPhotoUrl" target="_blank">
                <img v-bind:src="settings.screensaverPhotoUrl" class="responsive-img z-depth-1 photo" />
            </a>
            <div class="section">
              <a @click="deleteScreensaverImage()" class="btn red">Delete</a>
            </div>
          </div>

          <div class="file-field" style="width: 98px; display: inline-block;">
            <div class="btn blue">
              <span>Upload</span>
              <input class="text-box single-line" id="ScreensaverUpload" name="ScreensaverUpload" type="file" accept=".jpeg, .jpg, .gif, .png" @change="uploadScreensaverImage($event)" />
            </div>
            <div class="file-path-wrapper">
              <input class="file-path validate" type="text">
            </div>
          </div>

        </div>

      </div>

    </div>

    <div class="settings-section center-align">
      <button class="btn waves-effect waves-blue blue" @click="saveSchoolSettings()">Save</button>
    </div>

  </div>
</template>
