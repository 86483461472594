
<script>
import { defineComponent } from 'vue'
import Chart from 'chart.js'
import ExceptionDisplay from '@/components/ExceptionDisplay.vue'
import ValidationErrorDisplay from '@/components/ValidationErrorDisplay.vue'
import LoadingDisplay from '@/components/LoadingDisplay.vue'

export default defineComponent({
  components: { ExceptionDisplay, ValidationErrorDisplay, LoadingDisplay },
  name: 'ReportsClassesPage',
  data () {
    return {
      isLoading: false,
      form: {
        fromDate: '',
        fromTime: '',
        toDate: '',
        toTime: '',
        selectedClassId: '',
        classFilters: []
      },
      chartData: {
        datasets: [],
        labels: []
      },
      error: '',
      validationErrors: [],
      apiClient: {}
    }
  },

  async mounted () {
    this.apiClient = await this.$api.createApiClient()
    this.loadReport()
  },

  methods: {
    loadReport: function () {
      this.isLoading = true
      this.form = {}
      this.error = ''
      this.validationErrors = []

      this.apiClient
        .get('reports/classes')
        .then((r) => {
          this.form = r.data
        })
        .catch((e) => {
          const status = e.status || (e.response ? e.response.status : 0)
          if (status === 401) {
            this.$api.handleLoginExpired()
          } else if (status === 400) {
            this.handleBadRequest(e.response)
          } else {
            this.handleError(e)
          }
        })
        .then(() => {
          this.isLoading = false
        })
    },
    runReport: function () {
      const data = JSON.stringify(this.form)
      this.isLoading = true
      this.error = ''
      this.validationErrors = []
      this.chartData = {}
      this.apiClient
        .post('reports/classes', data)
        .then((r) => {
          this.chartData = r.data.chartData
          this.displayChart()
        })
        .catch((e) => {
          const status = e.status || (e.response ? e.response.status : 0)
          if (status === 401) {
            this.$api.handleLoginExpired()
          } else if (status === 400) {
            this.handleBadRequest(e.response)
          } else {
            this.handleError(e)
          }
        })
        .then(() => {
          this.isLoading = false
        })
    },
    handleBadRequest: function (e) {
      this.validationErrors = e.data.errors
    },
    handleError: function (e) {
      this.error = e.message
    },
    displayChart: function () {
      try {
        const ctx = document.getElementById('myChart')
        var myBarChart = new Chart(ctx, {
          type: 'line',
          data: this.chartData,
          options: {
            scales: {
              yAxes: [{
                ticks: {
                  min: 0
                }
              }]
            }
          }
        })
      } catch (e) {
        console.error(e)
      }
    }
  }
})
</script>

<template>

  <div class="row heading">
    <h4>Classes Report</h4>
    <p class="grey-text">Track class popularity over time</p>
  </div>

  <validation-error-display :validationErrors="validationErrors"></validation-error-display>
  <exception-display :error="error"></exception-display>
  <loading-display :isLoading="isLoading"></loading-display>

  <div v-show="!isLoading">

    <div class="row">
      <div class="col s12 l6">
          <div class="input-field">
              <strong>From date</strong>
              <input v-model="form.fromDate" type="date" />
          </div>
          <div class="input-field">
              <strong>From time</strong>
              <input v-model="form.fromTime" type="time" />
          </div>
      </div>

      <div class="col s12 l6">
          <div class="input-field">
              <strong>To date</strong>
              <input v-model="form.toDate" type="date" />
          </div>
          <div class="input-field">
              <strong>To time</strong>
              <input v-model="form.toTime" type="time" />
          </div>
      </div>
    </div>

    <div class="row">
      <div class="col s12 l6">
      </div>
      <div class="col s12 l6">
          <div>
              <strong>Class filter</strong>
              <select @change="form.selectedClassId = $event.target.value" class="browser-default">
                <option value="" selected="selected">All Classes</option>
                <option v-for="f in form.classFilters" :key="f.id" :value="f.id">{{ f.name }}</option>
              </select>
          </div>
      </div>
    </div>

    <div class="row">
      <div class="col s12 l6">
      </div>
      <div class="col s12 l6">
          <a @click="runReport()" class="btn waves-effect waves-blue blue right">Search</a>
      </div>
    </div>

    <!-- chart -->
    <div class="row">
      <div class="col s12 center-align">

          <div v-show="!chartData.datasets?.length">No results found</div>

          <div v-show="chartData.datasets?.length" id="chartContainer" class="chart-container" style="position: relative;">
              <canvas id="myChart"></canvas>
          </div>

      </div>
    </div>

  </div>

</template>
